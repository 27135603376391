import { Box, Toolbar, Typography, TextField, Grid } from '@mui/material';
import React, { useState } from 'react';
import Images from '../../utils/Images';
import PrimaryButton from '../../components/PrimaryButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

function ErrorScreen() {
    const navigate = useNavigate();
    const [cashier, setCashier] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [apiError, setApiError] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(false);

    const schema = Yup.object().shape({
        cashier: Yup.string().required('Cashier is required'),
        email: Yup.string().required('Email is required'),
    });

    const handleInputChange = (field, value) => {
        if (field === 'cashier') {
        setCashier(value);
        } else if (field === 'email') {
        setEmail(value);
        }
        setErrors({ ...errors, [field]: '' });
    };

    const validateForm = async () => {
        try {
            await schema.validate({ cashier, email }, { abortEarly: false });
            return true; // Form is valid
        } catch (error) {
        if (error instanceof Yup.ValidationError) {
            const newErrors = {};
            error.inner.forEach((err) => {
            newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }
        return false; // Form is invalid
        }
    };

    const handleButtonClick = async () => {
        const isValid = await validateForm();
        if (isValid) {
        setBtnDisabled(true);
        try {
            const response = await axios.get('https://xpanse.dev.bexcart.com/outlet/user/verify-account',{
                params: {
                    email: email,
                    idnumber: cashier
                }
            }); 
            if ( response.data.status === true ) {
                toast.success("Reset Link Sent to your registered Email", {
                    autoClose: 3000,
                });
            }
        } catch (error) {
            console.error('Error:', error);
            let errorMessage =  error.response.data.data.message;
            toast.error( errorMessage , {
                autoClose: 3000,
            });
        } finally {
            setBtnDisabled(false);
        }
        }
    };

    return (
    <>
        <Box sx={{ textAlign: 'center', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Grid container justifyContent={'center'}>
                <Grid item md={5} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', order: { xs: 2, sm: 2, md: 0 } }}>
                    <img alt='link-expired' src={Images.errorScreen} style={{ width: '100%'}} />
                </Grid>
                <Grid item md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img alt='xpanse-logo' src={Images.xpanseLabel} style={{ height: '140px', paddingBottom: '10px' }} />
                    <img alt='xpanse' src={Images.xpanse} style={{ height: '40px' }} />
                    <Typography variant='h6' sx={{ margin: '18px 0 10px 0' }}>This link has expired, Try resending the request</Typography> 
                </Grid>    
            </Grid>
        </Box>
    </>
    );
}

export default ErrorScreen;