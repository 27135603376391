const Images = {
    orders: require('../assets/images/orders.png'),
    dashboard: require('../assets/images/dashboard.png'),
    logout: require('../assets/images/logout.png'),
    product: require('../assets/images/product.png'),
    xpanse: require('../assets/images/Xpanse.png'),
    beans: require('../assets/images/beans.png'),
    profile: require('../assets/images/batmanProfile.jpg'),
    reviews: require('../assets/images/REVIEWS.png'),

    card: require('../assets/images/card.png'),
    cash: require('../assets/images/cash.png'),
    upi: require('../assets/images/upi.png'),

    noProductSelected: require('../assets/images/emptybox.png'),
    noOrders: require('../assets/images/noOrders.png'),
    qrImage: require('../assets/images/QRimage.png'),
    invoiceLogo: require('../assets/images/invoice-logo.png'),

    errorScreen: require('../assets/images/errorScreen.png'),
    xpanseLabel: require('../assets/images/xpanceLabel.png')
}

export default Images;