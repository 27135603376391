import { Box, Typography, Select, MenuItem, Button, Stack, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import axios from 'axios';
import { API } from '../utils/Api';

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const ManagerCalendar = ({ selectedCashierId }) => {
  const token = localStorage.getItem("token");
  const currentYear = new Date().getFullYear();
  const [openRecords, setOpenRecords] = useState(false);
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(currentYear);
  const [calendarData, setCalendarData] = useState([]);
  const [selectedDayData, setSelectedDayData] = useState(null);

  useEffect(() => {
    fetchData();
  }, [month, year]);

  const fetchData = async () => {
    try {
      const monthForAPI = month + 1;
      const url = `${API.BASE_URL}outlet/user/getMonthlyShiftRecords?month=${monthForAPI}&year=${year}&cashierId=${selectedCashierId}`;
      console.log('Cashier ID',selectedCashierId);
      const response = await axios.get( url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      console.log(response.data);
      setCalendarData(response.data.data.cashierRecords);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const renderCalendarDays = () => {
    const totalDays = daysInMonth(month, year);
    const startingDay = firstDayOfMonth(month, year);
    const calendarDays = [];
    for (let i = 0; i < startingDay; i++) {
      calendarDays.push(<div key={`empty-${i}`} className="empty-day"></div>);
    }
    for (let i = 1; i <= totalDays; i++) {
      const dayData = calendarData.find(data => data.dayOfMonth === i);
      let entriesPerDay = 0;
      if (dayData) {
        entriesPerDay = dayData.entriesPerDay;
      }
      calendarDays.push(
        <Box key={i} className="calendar-day cursor-p" onClick={() => handleOpenRecordsModal(dayData)}>
          <Typography className="dayNumber" variant='h5'>{i}</Typography>
          <Typography className="counter">{entriesPerDay}</Typography>
        </Box>
      );
    }
    return calendarDays;
  };

  const handleOpenRecordsModal = (dayData) => {
    setOpenRecords(true);
    setSelectedDayData(dayData);

    if (dayData && dayData.documents && dayData.documents.length > 0) {
    }
  };

  const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const firstDayOfMonth = (month, year) => {
    return new Date(year, month, 1).getDay();
  };

  const handlePrev = () => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const handleNext = () => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  const renderDaysOfWeek = () => {
    return daysOfWeek.map(day => (
      <div className="day-of-week" key={day}>
        {day}
      </div>
    ));
  };

  return (
    <Grid container>
        <Grid item md={9}>
            <Box className="calendar-container">
                <Box className="calendar">
                    <Box className="header">
                        <Box onClick={handlePrev} className='cursor-p'><ChevronLeft /></Box>
                        <Box>
                            <Select sx={{ border: 'none' }} value={month} onChange={handleChangeMonth}>
                                {Array.from({ length: 12 }, (_, monthIndex) => (
                                <MenuItem key={monthIndex} value={monthIndex}>
                                    {new Date(currentYear, monthIndex).toLocaleString('default', { month: 'long' })}
                                </MenuItem>
                                ))}
                            </Select>
                            <Select value={year} onChange={handleChangeYear}>
                                {Array.from({ length: 20 }, (_, yearIndex) => (
                                <MenuItem key={yearIndex} value={currentYear - 5 + yearIndex}>
                                    {currentYear - 5 + yearIndex}
                                </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Box onClick={handleNext} className='cursor-p'><ChevronRight /></Box>
                    </Box>
                    <Box className="days-of-week">{renderDaysOfWeek()}</Box>
                    <Box className="calendar-days">{renderCalendarDays()}</Box>
                </Box>
            </Box>
        </Grid>
        <Grid item md={3} sx={{ backgroundColor: '#F6F6F6', borderRadius: '5px' }}>
          { selectedDayData ? (
            <Box sx={{ padding: '20px'}}>
                <Typography sx={{ textAlign: 'center' }}>
                  {daysOfWeek[new Date(year, month, selectedDayData.dayOfMonth - 1).getDay()]}, {selectedDayData.dayOfMonth}th
                </Typography>
                <Typography sx={{ textAlign: 'center', borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>
                  {new Date(year, month, selectedDayData.dayOfMonth).toLocaleString('default', { month: 'long' })} {year}
                </Typography>
                {selectedDayData.documents.map((record, index) => (
                  <Stack key={index} direction={'row'} sx={{ justifyContent: 'space-around', padding: '20px 30px 15px 30px', borderBottom: '1px solid #ccc' }}>
                      <Stack direction={'row'}>
                          <Typography sx={{ fontWeight: '400' }}>In:</Typography>
                          <Typography sx={{ fontWeight: '600', marginLeft: '5px' }}>8:02am</Typography>
                      </Stack>
                      <Stack direction={'row'}>
                          <Typography sx={{ fontWeight: '400', marginLeft: '10px' }}>Out: </Typography>
                          <Typography sx={{ fontWeight: '600', marginLeft: '5px' }}> 8:02am</Typography>
                      </Stack>
                  </Stack>
                ))}
                <Typography sx={{ marginTop: '10px', fontSize: '14px' }}>Orders Managed</Typography>
                <Typography sx={{ fontWeight: '600' }}>102458</Typography>
                <Typography sx={{ marginTop: '10px', fontSize: '14px' }}>Amount Generated</Typography>
                <Typography sx={{ fontWeight: '600' }}>₹ 588551</Typography>
            </Box>
          ) : (
              <Box sx={{ padding: '20px'}}>
                <Typography>No records to display.</Typography>
              </Box>
            )
          }
        </Grid>
    </Grid>
  );
};

export default ManagerCalendar;