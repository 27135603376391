import React, { useState } from 'react';
import { Chip } from '@mui/material';

function FilterChip({ filterText, isSelected, onClick }) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <Chip
      label={filterText}
      onClick={onClick}
      variant={isSelected ? 'default' : 'outlined'}
      className={isSelected ? 'clickedChip' : 'unclickedChip'}
      clickable
      style={{ width: '100%' }}
    />
  );
}

export default FilterChip;

export function CategoryFilterChip({ filterText, isSelected, onClick }) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <Chip
      label={filterText}
      onClick={onClick}
      variant={isSelected ? 'default' : 'outlined'}
      className={isSelected ? 'clickedCategoryChip' : 'unclickedCategoryChip'}
      clickable
      style={{ width: '100%' }}
    />
  );
}
