// loginSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  user: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;

      // Save user data to localStorage upon successful login
      localStorage.setItem('userData', JSON.stringify(action.payload));
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;

      // Clear user data from localStorage upon logout
      localStorage.removeItem('userData');
    },
  },
});

export const { loginSuccess, logout } = loginSlice.actions;

export default loginSlice.reducer;