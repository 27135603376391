import React, { useEffect, useRef } from 'react';

const ScannerComponent = ({ targetSelector = 'body' }) => {
  const bufferRef = useRef('');
  const readingRef = useRef(false);
  let end = new Date(); 

  const shiftKeyCode = 16;
  const endingKeyCodes = [9, 13];
  const scanTime = 50;
  const timeBetweenChars = 12;
  const minChars = 5;

  const scannerRead = (e) => {
    e.stopPropagation();

    let tmpTime = 0;
    if (e.keyCode === shiftKeyCode) {
      return;
    }

    if (endingKeyCodes.includes(e.keyCode)) {
      fireEvent();
      return;
    } else if (!e.altKey && !e.ctrlKey) {
      bufferRef.current += e.key;
      tmpTime = end;
      end = new Date();
    }

    if (Math.ceil(tmpTime - end) < timeBetweenChars) {
      readingRef.current = false;
    }

    if (!readingRef.current) {
      end = new Date();
      readingRef.current = true;
      readingRef.current = setTimeout(fireEvent, scanTime);
    }
  };

  const fireEvent = () => {
    if (bufferRef.current.length < minChars) {
      reset();
      return;
    }

    const scannedData = bufferRef.current;
    const scanEvent = new CustomEvent('scanEnd', {
      detail: {
        value: scannedData,
      },
    });

    document.dispatchEvent(scanEvent);
    reset();
  };

  const reset = () => {
    bufferRef.current = '';
    readingRef.current = false;
  };

  useEffect(() => {
    const targetElement = document.querySelector(targetSelector);
    targetElement.addEventListener('keydown', scannerRead);

    return () => {
      targetElement.removeEventListener('keydown', scannerRead);
    };
  }, [targetSelector]);

  return null;
};

export default ScannerComponent;