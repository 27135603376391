import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import LoopIcon from '@mui/icons-material/Loop';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { loginSuccess, logout } from '../../src/store/Login/loginSlice'
import { useState } from 'react';
import { CancelOutlined, CheckBox, CheckCircleOutline, FiberManualRecordOutlined, FiberManualRecordRounded, KeyboardArrowDown } from '@mui/icons-material';
import { Hidden, IconButton, Popover, Table, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API } from '../utils/Api';

const drawerWidth = 130;

export default function NavBar() {
  const [currentDateTime, setCurrentDateTime] = React.useState(getCurrentDateTime());
  const pathName = useLocation().pathname;
  const navigate = useNavigate();
  const [tokenPresent, setTokenPresent] = React.useState(!!localStorage.getItem('token'));
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const storedOutletData = localStorage.getItem('outletData');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const outletData = JSON.parse(storedOutletData);
  const userRole = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const [isOutletOpen, setIsOutletOpen] = useState(!outletData?.isOutletClosed);
  const [isOutletPickupOrder, setIsOutletPickupOrder] = useState(outletData?.orderAcceptType === "PICKUP");

  // console.log('jksfdngjkbnzfg ksfngkjn', outletData);

  function getCurrentDateTime() {
    const now = new Date();

    const dayOptions = { weekday: 'long' };
    const dayOfWeek = now.toLocaleDateString('en-US', dayOptions);

    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = now.toLocaleDateString('en-US', dateOptions);

    const timeOptions = { hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const time = now.toLocaleTimeString('en-US', timeOptions);

    return `${date} ${dayOfWeek} | ${time}`;
  }

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(getCurrentDateTime());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const reload = () => {
    navigate(0);
  };

  const handleInventoryClick = () => {
    navigate('/inventory');
  };
  
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleOuletStatus = async () => {
      try {
        const response = await axios.put(
          `${API.BASE_URL}outlet/user/open-close-outlet`,
          {
            "closeOutlet": isOutletOpen,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          console.log('Status updated successfully:', response.data);
          const outletData = response.data.data.outlet;
          localStorage.setItem('outletData', JSON.stringify(outletData));
          setIsOutletOpen(!isOutletOpen);
          toast.success("Outlet status updated!", {
            autoClose: 3000,
          });
        } else {
          console.error('Error Outlet status updated:', response.data);
        }
      } catch (error) {
        console.error('Error in the Outlet status updated:', error);
      }
  };

  const handleDeliveryStatus = async () => {
    const newOrderAcceptType = isOutletPickupOrder ? "ALL" : "PICKUP";
    setIsOutletPickupOrder(newOrderAcceptType === "PICKUP");
    try {
      const response = await axios.put(
          `${API.BASE_URL}outlet/orders-accept-type`,
          { "orderAcceptType": newOrderAcceptType },
          { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status !== 200) {
          throw new Error('Failed to confirm the update on the server');
      }
      console.log('Order accept status updated successfully:', response.data);
      const outletData = response.data.data.dbResponse;
      localStorage.setItem('outletData', JSON.stringify(outletData));
      toast.success(`Order accept status updated!`, { autoClose: 3000 });
    } catch (error) {
      setIsOutletPickupOrder(newOrderAcceptType !== "PICKUP");
      console.error('Error updating the Outlet status:', error);
      toast.error(`Error updating status: ${error.message}`);
    }
  };

  return (
    <>
      <CssBaseline />
      <Box className='loginLogo' sx={{ width: `calc(${drawerWidth}px)` }}>
        <Typography className='head' sx={{ color: '#fff' }}>
          Xpanse
        </Typography>
        <Typography className='para' sx={{ color: '#fff' }}>
          POS
        </Typography>
      </Box>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          height: '90px',
          background: '#003049',
          flexDirection: 'row',
          justifyContent: 'space-between',
          boxShadow: '0',
        }}
      >
        <Hidden mdDown>
          <Toolbar>
            <Typography sx={{ width: '420px' }} variant="h6" noWrap component="div">
              {currentDateTime}
            </Typography>
          </Toolbar>
          { isLoggedIn && (
            <>
            {outletData && outletData?.name && (
              <Toolbar>
                <Box onClick={handlePopoverOpen} sx={{ backgroundColor: 'white', borderRadius: '50px', color: 'black', padding: '0 15px 0 15px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                  <Typography  variant="p">{outletData?.name}</Typography>
                  <KeyboardArrowDown />
                  {isOutletOpen ? (
                    <FiberManualRecordRounded color='success' fontSize='12px' />
                  ) : (
                    <FiberManualRecordRounded color='error' fontSize='12px' />
                  )}
                </Box>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Box p={2} sx={{ width: '350px', background: '#f0f0f0', borderRadius: '4px' }}>
                    <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" scope="row" sx={{ borderBottom: "none", fontWeight: 'bold' }}>
                              Name:
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                              {outletData.name}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" sx={{ borderBottom: "none", fontWeight: 'bold' }}>
                              Address:
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                              {outletData.address}, {outletData.city}, {outletData.state}, {outletData.pincode}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" sx={{ borderBottom: "none", fontWeight: 'bold' }}>
                              Email:
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                              {outletData.email}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" sx={{ borderBottom: "none", fontWeight: 'bold' }}>
                              Contact:
                            </TableCell>
                            <TableCell sx={{ borderBottom: "none" }}>
                              {outletData.phone}
                            </TableCell>
                          </TableRow>
                          {outletData?.openingTime && outletData?.closingTime && (
                            <TableRow>
                              <TableCell component="th" scope="row" sx={{ borderBottom: "none", fontWeight: 'bold' }}>
                                Operating Hours:
                              </TableCell>
                              <TableCell sx={{ borderBottom: "none" }}>
                                {outletData.openingTime} - {outletData.closingTime}
                              </TableCell>
                            </TableRow>
                          )}
                          {userRole === 'MANAGER' && (
                            <>
                              <TableRow>
                                <TableCell component="th" scope="row" sx={{ borderBottom: "none", fontWeight: 'bold' }}>
                                  Outlet Status
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                  <IconButton onClick={handleOuletStatus} color="primary">
                                    {isOutletOpen ? (
                                      <> <CheckCircleOutline color="success" /> <Typography sx={{ paddingLeft: '10px', color: 'green' }}>Opened</Typography> </>
                                    ) : (
                                      <> <CancelOutlined color="error" /> <Typography sx={{ paddingLeft: '10px', color: 'red' }}>Closed</Typography> </>
                                    )}
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell component="th" scope="row" sx={{ borderBottom: "none", fontWeight: 'bold' }}>
                                  Delivery Orders
                                </TableCell>
                                <TableCell sx={{ borderBottom: "none" }}>
                                  <IconButton onClick={handleDeliveryStatus} color="primary">
                                    {isOutletPickupOrder ? (
                                      <> <CancelOutlined color="error" /> <Typography sx={{ paddingLeft: '10px', color: 'red' }}>Not Accepting</Typography> </>
                                    ) : (
                                      <> <CheckCircleOutline color="success" /> <Typography sx={{ paddingLeft: '10px', color: 'green' }}>Accepted</Typography> </>
                                    )}
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                        </TableBody>
                    </Table>
                  </Box>
                </Popover>
              </Toolbar>
            )}
            </>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {isLoggedIn && !outletData?.isEventOutlet && (
              <Toolbar
                onClick={handleInventoryClick}
                sx={{
                  flexDirection: 'column',
                  paddingTop: '8px',
                  backgroundColor: '#fff',
                  alignSelf: 'center',
                  width: '61px',
                  height: '61px',
                  marginRight: '30px',
                  borderRadius: '4px',
                }}
                className="cursor-p"
              >
                <Inventory2OutlinedIcon sx={{ color: '#000' }} />
                <Typography variant="p" sx={{ marginTop: '3px', fontSize: '12px', color: '#000' }}>
                  Inventory
                </Typography>
              </Toolbar>
            )}
          </Box>
        </Hidden>
      </AppBar>
    </>
  );
}