import { Box, Typography, Select, MenuItem, Button, Stack, Dialog, DialogTitle, DialogContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import axios from 'axios';
import { API } from '../utils/Api';

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const ShiftCalendar = () => {
  const token = localStorage.getItem("token");
  const currentYear = new Date().getFullYear();
  const [openRecords, setOpenRecords] = useState(false);
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(currentYear);
  const [calendarData, setCalendarData] = useState([]);
  const [selectedDayData, setSelectedDayData] = useState(null);

  const fetchData = async () => {
    try {
      const monthForAPI = month + 1;
      const url = `${API.BASE_URL}outlet/user/getMonthlyShiftRecords?month=${monthForAPI}&year=${year}`;
      console.log(url);
      const response = await axios.get( url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      setCalendarData(response.data.data.cashierRecords);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const firstDayOfMonth = (month, year) => {
    return new Date(year, month, 1).getDay();
  };

  const handlePrev = () => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const handleNext = () => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const handleOpenRecordsModal = (dayData) => {
    setOpenRecords(true);
    setSelectedDayData(dayData);

    if (dayData && dayData.documents && dayData.documents.length > 0) {
    }
  };

  const handleCloseRecordsModal = () => {
      setOpenRecords(false);
  };

  const handleChangeMonth = (event) => {
    setMonth(event.target.value);
  };

  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };

  const renderDaysOfWeek = () => {
    return daysOfWeek.map(day => (
      <div className="day-of-week" key={day}>
        {day}
      </div>
    ));
  };

  const renderCalendarDays = () => {
    const totalDays = daysInMonth(month, year);
    const startingDay = firstDayOfMonth(month, year);
    const calendarDays = [];
    for (let i = 0; i < startingDay; i++) {
      calendarDays.push(<div key={`empty-${i}`} className="empty-day"></div>);
    }
    for (let i = 1; i <= totalDays; i++) {
      const dayData = calendarData.find(data => data.dayOfMonth === i);
      let entriesPerDay = 0;
      if (dayData) {
        entriesPerDay = dayData.entriesPerDay;
      }
      calendarDays.push(
        <Box key={i} className="calendar-day cursor-p" onClick={() => handleOpenRecordsModal(dayData)}>
          <Typography className="dayNumber" variant='h5'>{i}</Typography>
          <Typography className="counter">{entriesPerDay}</Typography>
        </Box>
      );
    }
    return calendarDays;
  };
  
  const getCurrentDayFirstInRecordTime = () => {
    const currentDay = new Date().getDate();
    const currentMonth = new Date().getMonth() + 1;

    const currentDayRecord = calendarData.find(record => {
      return (
        record.dayOfMonth === currentDay &&
        record.currentMonth === currentMonth.toString() &&
        record.documents.length > 0 &&
        record.documents[0].recordType === "IN"
      );
    });

    if (currentDayRecord) {
      const firstInRecordTime = currentDayRecord.documents[0].time;
      return firstInRecordTime;
    }
    return null;
  };

  const firstInRecordTime = getCurrentDayFirstInRecordTime();
  
  useEffect(() => {
    fetchData();
  }, [month, year]);

  return (
    <>
    <Box className="calendar-container">
      <Box className="calendar">
        <Box className="header">
          <Box onClick={handlePrev} className='cursor-p'><ChevronLeft /></Box>
          <Box>
            <Select sx={{ border: 'none' }} value={month} onChange={handleChangeMonth}>
                {Array.from({ length: 12 }, (_, monthIndex) => (
                <MenuItem key={monthIndex} value={monthIndex}>
                    {new Date(currentYear, monthIndex).toLocaleString('default', { month: 'long' })}
                </MenuItem>
                ))}
            </Select>
            <Select value={year} onChange={handleChangeYear}>
                {Array.from({ length: 20 }, (_, yearIndex) => (
                <MenuItem key={yearIndex} value={currentYear - 5 + yearIndex}>
                    {currentYear - 5 + yearIndex}
                </MenuItem>
                ))}
            </Select>
          </Box>
          <Box onClick={handleNext} className='cursor-p'><ChevronRight /></Box>
        </Box>
        <Box className="days-of-week">{renderDaysOfWeek()}</Box>
        <Box className="calendar-days">{renderCalendarDays()}</Box>
      </Box>
    </Box>
    <Dialog open={openRecords} onClose={handleCloseRecordsModal}>
      <DialogContent sx={{ backgroundColor: '#ccc' }}>
        <Box sx={{ padding: '20px' }}>
          {selectedDayData ? (
            <>
              <Typography sx={{ textAlign: 'center' }}>
                {daysOfWeek[new Date(year, month, selectedDayData.dayOfMonth - 1).getDay()]}, {selectedDayData.dayOfMonth}th
              </Typography>
              <Typography sx={{ textAlign: 'center', borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>
                {new Date(year, month, selectedDayData.dayOfMonth).toLocaleString('default', { month: 'long' })} {year}
              </Typography>
              {selectedDayData.documents.map((record, index) => (
                <Stack key={index} direction={'row'} sx={{ justifyContent: 'space-around', padding: '20px 30px 15px 30px', borderBottom: '1px solid #000' }}>
                  <Stack direction={'row'}>
                    <Typography sx={{ fontWeight: '400' }}>In:</Typography>
                    <Typography sx={{ fontWeight: '600', marginLeft: '5px' }}>{`${record.hours}:${record.minutes} ${record.time}`}</Typography>
                  </Stack>
                  <Stack direction={'row'}>
                    <Typography sx={{ fontWeight: '400', marginLeft: '15px' }}>Out:</Typography>
                    <Typography sx={{ fontWeight: '600', marginLeft: '5px' }}>{`${record.hours}:${record.minutes} ${record.time}`}</Typography>
                  </Stack>
                </Stack>
              ))}
            </>
          ) : (
            <Typography>No data available for this day.</Typography>
          )}
        </Box>
      </DialogContent>
    </Dialog>
    </>
  );
};

export default ShiftCalendar;