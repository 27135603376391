import React, { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { API } from '../utils/Api';

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('outletData')));
  // console.log('outletData._id', outletData._id);
  
  useEffect(() => {
    if (!outletData?._id) {
      console.error('Outlet data is missing');
      return;
    }

    const SOCKET_OPTIONS = {
      pingInterval: 15000,
      pingTimeout: 30000,
    };

    // Initialize Socket Connection.
    const newSocket = io(API.SOCKET_URL, SOCKET_OPTIONS);

    newSocket.on('ping', () => {
      console.log('Ping sent to server');
    });

    newSocket.on('pong', () => {
      console.log('Pong received from server');
    });

    newSocket.on('connect_error', (err) => {
      console.error('Connection error:', err);
    });

    setSocket(newSocket);

    // Clean up socket connection when component unmounts.
    return () => {
      newSocket.disconnect();
    };
  }, [outletData?._id]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
