import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NavBar from './NavBar';
import LanguageIcon from '@mui/icons-material/Language';
import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import axios from 'axios';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { API } from '../utils/Api';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../src/store/Login/loginSlice';
import { AssessmentOutlined } from '@mui/icons-material';

const drawerWidth = 130;

export default function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("role");
  const [selectedRoute, setSelectedRoute] = React.useState('./onlineOrders');
  const userData = useSelector((state) => state.login.user); 
  const dispatch = useDispatch();
  const storedOutletData = localStorage.getItem('outletData');
  const outletData = JSON.parse(storedOutletData);

  const userName = userData && userData.user ? userData.user.name : '';

  const menuItems = [
    { text: 'Orders', icon: <LanguageIcon />, route: './onlineOrders' },
    // { text: 'Orders', icon: <DataUsageOutlinedIcon />, route: './orders' },
    { text: 'Dashboard', icon: <DashboardIcon />, route: userRole === 'CASHIER' ? './dashboard' : './managerDashboard' },
    ...(!outletData?.isEventOutlet ? (
      userRole === 'MANAGER'
        ? [{ text: 'Reports', icon: <AssessmentOutlined />, route: './reports' }]
        : []
    ) : []),
    ...(!outletData?.isEventOutlet ? [
      { text: 'Products', icon: <CategoryOutlinedIcon />, route: './products' }
    ] : [])
  ];

  React.useEffect(() => {
    navigate(selectedRoute);
  }, []);

  const handleItemClick = (route) => {
    navigate(route);
    setSelectedRoute(route);
  };

  const handleButtonClick = async () => {
    try {
      const response = await axios.get(`${API.BASE_URL}outlet/user/signout`,{
        headers: {
          Authorization: `Bearer ${token}`
        }
      }); 
      if ( response.data.status === true ) {
        // console.log('Logout successful:', response.data);
        toast.success("Logout successful!", {
          autoClose: 3000,
        });
        navigate('/', { state: { message: true } });
        localStorage.clear();
        localStorage.setItem("isLoggedIn", 'false');
        dispatch(logout());
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#0C0F14', overflowY: 'hidden', height: '100%' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
      >
        <NavBar />
      </AppBar>
      <Drawer
        sx={{
          bgcolor: '#000',
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            bgcolor: '#0C0F14',
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar sx={{ flexDirection: 'column', marginTop: '15px' }}>
          <Typography sx={{ color: '#fff' }} variant="h5">
            Xpanse
          </Typography>
          <Typography sx={{ color: '#fff' }} paragraph>
            POS
          </Typography>
        </Toolbar>
        <List className='sideBar' sx={{}}>
          {menuItems.map((item, index) => (
            <ListItem className='sideBar-btnContainer' key={item.text} disablePadding>
              <ListItemButton
                className='sideBar-button'
                onClick={() => handleItemClick(item.route)}
                sx={{
                  backgroundColor: selectedRoute === item.route ? '#003049' : '#fff',
                  '&:hover': {
                    backgroundColor: selectedRoute === item.route ? '#003049' : '#fff',
                  },
                }}
              >
                <ListItemIcon sx={{ color: selectedRoute === item.route ? '#fff' : '#000' , justifyContent: 'center' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  className='sideBar-btnText'
                  primaryTypographyProps={{ sx: { [theme.breakpoints.down('xs')]: { fontSize: '8px' }, [theme.breakpoints.between('sm', 'md')]: { fontSize: '8px' }, [theme.breakpoints.up('md')]: { fontSize: '11px' },} }}
                  sx={{ margin: '0px', color: selectedRoute === item.route ? '#fff' : '#000', justifyContent: 'center' }}
                  primary={item.text}
                />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem sx={{ }}>
            <ListItemButton onClick={() => { navigate('/profile'); setSelectedRoute('') }} sx={{ backgroundColor: '#0C0F14', borderRadius: '4px',  margin: '5px 2px 0px 2px', alignSelf: 'center', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', alignContent: 'center' }}>
              <ListItemIcon sx={{ color: '#fff', justifyContent: 'center' }}>
                <AccountCircleOutlinedIcon sx={{ fontSize: '35px'}} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ sx: { fontSize: '16px' }}} sx={{ color: '#fff', justifyContent: 'center' }}>
                {userName}
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem className='sideBar-btnContainer' disablePadding sx={{ fontSize: '12px', margin: '0px' }}>
            <ListItemButton
              onClick={handleButtonClick}
              className='sideBar-button'
              sx={{
                backgroundColor: '#fff',
                '&:hover': {
                  backgroundColor: '#003049',
                },
              }} 
            >
              <ListItemIcon sx={{ color: '#000', justifyContent: 'center', '&:hover': { color: '#fff' } }}>
                <LoginOutlinedIcon />
              </ListItemIcon>
              <ListItemText 
                className='sideBar-btnText'
                primaryTypographyProps={{ sx: { '&:hover': { color: '#fff' }, [theme.breakpoints.down('xs')]: { fontSize: '8px' }, [theme.breakpoints.between('sm', 'md')]: { fontSize: '8px' }, [theme.breakpoints.up('md')]: { fontSize: '11px' },} }}
              >
                Logout  
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
}

// import { Box, Grid, Typography, Tab, Tabs, CircularProgress, Button, Modal, Dialog, DialogActions, MenuItem, Menu } from '@mui/material';
// import React, { useEffect, useState, useRef, useMemo, useContext } from 'react';
// import PrimaryButton from '../../components/PrimaryButton';
// import OrderCard from '../../components/OrderCard';
// import axios, { all } from 'axios';
// import { API } from '../../utils/Api';
// import Images from '../../utils/Images';
// import { toast } from 'react-toastify';
// import invoice from '../../assets/images/invoice-logo.png';
// import ReactDOMServer from 'react-dom/server';
// import { QRCodeSVG } from 'qrcode.react';
// import LabelPrinter from '../../components/LabelPrinter';
// import { CheckBoxOutlineBlankOutlined, CheckBoxRounded } from '@mui/icons-material';
// import { Howl } from 'howler';
// import notificationSound from '../../assets/sounds/notificationSound.mp3';
// import { useLocation } from 'react-router-dom';
// import KOTPrinter from '../../components/KOTPrinter';
// import { useSocket, SocketProvider } from '../../context/SocketContext';

// const getPrintedKOTOrders = () => {
//   return JSON.parse(localStorage.getItem('printedKOTOrders')) || [];
// };

// const IDLE_TIMEOUT = 10; 

// function OnlineOrders() {
//   // const socket = useContext(SocketProvider);
//   const socket = useSocket();
//   const token = localStorage.getItem("token");
//   const riderApi = localStorage.getItem("riderAPIKey");
//   const riderApiSecret = localStorage.getItem("riderAPISecret");
//   const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('outletData')));
//   const outletId = outletData._id;
//   const [currentTab, setCurrentTab] = useState(0);
//   const [orders, setOrders] = useState([]);
//   const [selectedOrder, setSelectedOrder] = useState(null);
//   const [printKotDisabled, setPrintKotDisabled] = useState(false);
//   const [checkedItems, setCheckedItems] = useState({});
//   const [loading, setLoading] = useState(false);
//   const [qrCodeSvg, setQrCodeSvg] = useState(null);
//   const [orderReadyToPick, setOrderReadyToPick] = useState(false);
//   const [checkboxesDisabled, setCheckboxesDisabled] = useState(false);
//   const [scannedData, setScannedData] = useState("");
//   const [hiddenCheckboxes, setHiddenCheckboxes] = useState([]);
//   const [ridersData, setRidersData] = useState([]);
//   const [authTokenForRider, setAuthTokenForRider] = useState('');
//   const [openRiders, setOpenRiders] = useState(false);
//   const [selectedRider, setSelectedRider] = useState([]);
//   const [riderPickLoading, setRiderPickLoading] = useState(false);
//   const [allOrdersSort, setAllOrdersSort] = useState('true');
//   const [ordersType, setOrdersType] = useState('ALL');
//   const location = useLocation();
//   const [anchorElSort, setAnchorElSort] = useState(null);
//   const [anchorElFilter, setAnchorElFilter] = useState(null);
//   const [scanUrl, setScanUrl] = useState('');
//   const message = location.state?.message;
//   const [isScrolled, setIsScrolled] = useState(false);
//   // const [idleTimer, setIdleTimer] = useState(IDLE_TIMEOUT);
//   // let idleTimeout = null;

//   useEffect(() => {
//     const handleScroll = () => {
//       const scrollTop = document.querySelector('.ordersGrid')?.scrollTop || 0;
//       setIsScrolled(scrollTop > 10);
//     };
//     const ordersGrid = document.querySelector('.ordersGrid');
//     ordersGrid?.addEventListener('scroll', handleScroll);
//     return () => {
//       ordersGrid?.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   const handleSortClick = (event) => {
//     setAnchorElSort(event.currentTarget);
//   };

//   const handleSortClose = (sortOption) => {
//     setAllOrdersSort(sortOption);
//     setAnchorElSort(null);
//     setSelectedOrder(null);
//   };

//   const handleFilterClick = (event) => {
//     setAnchorElFilter(event.currentTarget);
//   };

//   const handleFilterClose = (filterOption) => {
//     setOrdersType(filterOption);
//     setAnchorElFilter(null);
//     setSelectedOrder(null);
//   };

//   useEffect(() => {
//     if (message === true) {
//       window.location.reload();
//     }
//   }, [message]);
  
//   const today = new Date();
//   const year = today.getFullYear();
//   const month = (today.getMonth() + 1).toString().padStart(2, '0');
//   const day = today.getDate().toString().padStart(2, '0');

//   const formattedDate = `${year}-${month}-${day}`;

//   const setOpenRidersModal = () => {
//     setOpenRiders(true);
//   };

//   const setCloseRidersModal = () => {
//     setOpenRiders(false);
//     setSelectedRider([]);
//   };

//   useEffect(() => {
//     const generateQRCode = () => {
//       const qrCodeData = JSON.stringify(selectedOrder.order._id);
//       const qrCodeElement = <QRCodeSVG value={qrCodeData} />;
//       const generatedQrCodeSvg = ReactDOMServer.renderToStaticMarkup(qrCodeElement);
//       setQrCodeSvg(generatedQrCodeSvg);
//     };
//     if (selectedOrder) {
//       generateQRCode();
//     }
//   }, [selectedOrder]);

//   const handleChangeTab = (event, newValue) => {
//     setCurrentTab(newValue);
//     setSelectedOrder(null);
//   };

//   const fetchData = async () => {
//     setLoading(true);
//     try {
//       let url = `${API.BASE_URL}outlet/orders?allOrders=${allOrdersSort}&fromDate=${formattedDate}&toDate=${formattedDate}`;
//       // let url = `${API.BASE_URL}outlet/orders?allOrders=${allOrdersSort}&fromDate=2024-10-14&toDate=2024-10-14`;
//       if (ordersType !== 'ALL') {
//         url += `&ordersType=${ordersType}`;
//       }
//       const response = await axios.get( url, {
//         headers: {
//           Authorization: `Bearer ${token}`
//         },
//       });
//       setLoading(false);
//       const newOrders = response.data.data.orders;
//       const newOrderLength = newOrders.length;
//       const storedOrderLength = localStorage.getItem('orderLength') ? parseInt(localStorage.getItem('orderLength'), 10) : 0;
//       localStorage.setItem('orderLength', newOrderLength);
//       if (newOrderLength > storedOrderLength) {
//         playSound();
//       }
//       setOrders(newOrders);
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       setLoading(false);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // useEffect(() => {
//   //   fetchData();
//   //   const intervalId = setInterval(() => {
//   //     fetchData();
//   //   }, 25000);
//   //   return () => clearInterval(intervalId);
//   // }, [ordersType, allOrdersSort]);

//   useEffect(() => {
//     fetchData();
//   }, [ordersType, allOrdersSort]);

  
//   useEffect(() => {
//     if (!socket || !outletId) return;
  
//     console.log('Connected to socket server');
  
//     // Emit event to join the outlet room based on outletId
//     socket.emit('joinOutletRoom', { outletId });
  
//     // Listen for 'orderCreated' event after joining the room
//     socket.on('orderCreated', (newOrder) => {
//       console.log('New order received:', newOrder);
//       fetchData();  // Fetch updated data when a new order is created
//     });
  
//     // Cleanup to avoid duplicate event listeners
//     return () => {
//       socket.off('orderCreated');
//     };
  
//   }, [socket, outletId, fetchData]); 

//   // useEffect(() => {
//   //   fetchData();
//   //   if (!socket) return;
//   //   socket.on('orderCreated', (newOrder) => {
//   //     console.log('New order received:', newOrder);
//   //     fetchData();
//   //   });
//   //   return () => {
//   //     socket.off('orderCreated');
//   //   };
//   // }, [socket]);

//   function playSound() {
//     const sound = new Howl({
//       src: [notificationSound],
//       volume: 1.0,
//     });
//     sound.play();
//   }

//   const handleOrderClick = (orderId) => {
//     const selectedOrderDetails = orders.find((order) => order?.order?._id === orderId);
//     setSelectedOrder(selectedOrderDetails);
//     // console.log('Selected order', selectedOrder);
//   };

//   const isButtonDisabled = () => {
//     return !selectedOrder.orderItems.every((item) => item.isReady);
//   };

//   const handleReadyToPickClick = async () => {
//     setRiderPickLoading(true);
//     setOrderReadyToPick(true);
//     try {
//       const orderPayload = {
//         _id: selectedOrder.order._id
//       };
//       if (selectedOrder?.order?.ordersType === 'DELIVERY') {
//         orderPayload.riderId = selectedRider.id;
//       }
//       const response = await axios.put(`${API.BASE_URL}order/ready-to-pick`,
//         orderPayload,
//         { headers: { Authorization: `Bearer ${token}` }, }
//       );
//       if (response.status === 200) {
//         toast.success("Order set as ready to pick", { autoClose: 3000, });
//         fetchData();
//         setOpenRiders(false);
//         setRiderPickLoading(false);
//         await refreshOrdersData();
//       } else {
//         console.error('Error setting order as ready to pick', response.data);
//         setRiderPickLoading(false);
//       }
//     } catch (error) {
//       console.error('Error setting order as ready to pick:', error?.response?.data);
//       setRiderPickLoading(false);
//       const errorMessage = error?.response?.data?.data?.message || "Try again later";
//       toast.error(errorMessage, { autoClose: 3000 });
//       if (error?.response?.data?.data?.isOrderCancelled) {
//         setSelectedOrder(null);
//         setCloseRidersModal();
//       }
//     } finally {
//       setRiderPickLoading(false);
//     }
//   };
  
//   const refreshOrdersData = async () => {
//     setLoading(true);
//     try {
//       const url = `${API.BASE_URL}outlet/orders?allOrders=${allOrdersSort}&fromDate=${formattedDate}&toDate=${formattedDate}`;
//       const response = await axios.get(url, {
//         headers: {
//           Authorization: `Bearer ${token}`
//         },
//       });
//       const updatedOrders = response?.data?.data?.orders;
//       setOrders(updatedOrders);
//       const updatedSelectedOrder = updatedOrders.find(order => order.order._id === selectedOrder.order._id);
//       setSelectedOrder(updatedSelectedOrder);
//     } catch (error) {
//       console.error('Error refreshing data:', error);
//       toast.error("Failed to refresh order data.", { autoClose: 3000 });
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleReadyToPickForDelivery = async () => {
//     fetchRiders();
//   }

//   const handleBillButtonClick = async () => {
//     // if (!isButtonDisabled()) {
//       // console.log('Completed and printed bill');
//       try {
//         const response = await axios.put(
//           `${API.BASE_URL}order/completed`,
//           {
//             _id: selectedOrder.order._id,
//           },
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         );
//         if (response.status === 200) {
//           console.log('Delivered', response.data);
//           setSelectedOrder(null);
//         } else {
//           console.error('Error setting order as delivered:', response.data);
//         }
//       } catch (error) {
//         console.error('Error setting order as delivered:', error);
//       }
//       fetchData();
//       handlePrintPreview(selectedOrder);
//       toast.success("Completed and printed bill!", {
//         autoClose: 3000,
//       });
//     // }
//   };

//   const getTokenForAuthorize = async () => {
//     try {
//       const response = await axios.post(
//         'https://devapis.zupzap.com/v1/restful/authorize',
//         {},
//         {
//           headers: {
//             'X-API-Key': riderApi,
//             'X-API-Secret': riderApiSecret,
//           },
//         }
//       );
//       if (response.status === 200) {
//         console.log('Fetched new token for authorization', response.data);
//         return response.data.data.access_token;
//       } else {
//         console.error('Error fetching new token for authorization:', response.data);
//         return null;
//       }
//     } catch (error) {
//       console.error('Error fetching new token for authorization', error);
//       return null;
//     }
//   };

//   const handleRidersCall = async (authTokenForRider) => {
//     try {
//       const response = await axios.get(
//         `https://devapis.zupzap.com/v1/restful/riders/list`,
//         {
//           headers: {
//             Authorization: authTokenForRider,
//           },
//         }
//       );
//       if (response.status === 200) {
//         // console.log('Fetched riders', response.data.data.list);
//         setRidersData(response?.data?.data?.list);
//         setOpenRiders(true);
//       } else {
//         // console.error('Error fetching the riders:', response.data);
//         toast.error("Error fetching the riders", { autoClose: 3000 });
//       }
//     } catch (error) {
//       // console.error('Error fetching the riders', error);
//       toast.error(error?.response?.data?.data?.message || "Error fetching the riders", { autoClose: 3000 });
//     }
//   };

//   const fetchRiders = async () => {
//     const authTokenForRider = await getTokenForAuthorize();
//     if (authTokenForRider) {
//       await handleRidersCall(authTokenForRider);
//     } else {
//       // console.error('Failed to fetch authorization token, skipping riders call');
//       toast.error("Failed to fetch authorization token, skipping riders call", { autoClose: 3000 })
//     }
//   };

//   function getFormattedDate() {
//     const today = new Date();
//     const day = today.getDate().toString().padStart(2, '0');
//     const month = (today.getMonth() + 1).toString().padStart(2, '0');
//     const year = today.getFullYear();

//     return `${day}-${month}-${year}`;
//   }

//   const handlePrintPreview = (selectedOrder) => {
//     const order = selectedOrder;
//     let customerInfo = '';
//     if (order?.order?.kioskUser) {
//       customerInfo = `
//         <span style="font-size: 10px;">
//           ${order?.order?.kioskUser?.name}<br/>
//           ${order?.order?.kioskUser?.phone.replace(/\d(?=\d{4})/g, '*')}
//         </span>
//       `;
//     } else {
//       customerInfo = `
//         <span style="font-size: 10px;">
//           ${order?.order?.user?.name}<br>
//           ${order?.order?.user?.mobile.replace(/\d(?=\d{4})/g, '*')}
//         </span>
//       `;
//     }

//     const qrCodeData = "https://r8rpc-alternate.app.link";
//     const qrCodeElement = <QRCodeSVG value={qrCodeData} />;
//     const qrCodeSvg = ReactDOMServer.renderToStaticMarkup(qrCodeElement);
    
//     const printContent = `
//     <html lang="en">
//     <head>
//       <meta charset="UTF-8" />
//       <meta name="viewport" content="width=device-width, initial-scale=1.0" />
//       <link rel="preconnect" href="https://fonts.googleapis.com">
//       <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
//       <link href="https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap" rel="stylesheet" onload="print()" > 
//       <title>XPANSE</title>
//       <style>
//         body{
//           font-family: "Roboto Mono", monospace;
//           font-weight: 700 !important;
//         }
//         h1 {
//           padding: 0px;
//           margin: 0px;
//         }
//         p {
//           font-size: 10px;
//           margin-bottom: 15px;
//         }
//         table {
//           width: 100%;
//           text-transform: uppercase;
//           font-weight: 700;
//         }
//         table th, table td {
//           font-size: 12px;
//         }
//         table th {
//           vertical-align: top;
//         }
//         table th, table td {
//           padding: 5px;
//         }
//         table thead td {
//           text-align: left;
//           vertical-align: top;
//         }
//         table tfoot td {
//           text-align: right;
//           vertical-align: top;
//         }
//         small {
//           font-size: 10px;
//         }
//         h3 {
//           font-size: 14px;
//         }
//       </style>
//     </head>
//     <body>
//       <center>
//         <img style="width: 20%;" src=${invoice} />
//         <p style="text-transform: uppercase; margin: 12px 0 0 0px !important; padding: 0px !important;">GST No. 30AAKCT9798D1ZX</p>
//         <p style="text-transform: uppercase; margin: 0px !important; padding: 0px !important;">FSSAI No. 10624001000194</p>
//         <p style="margin: 0px 0 0 0px !important; padding: 0px !important;">${outletData.name}, ${outletData.address}, ${outletData?.city}, ${outletData?.state}, ${outletData?.pincode}</p>
//         <p style="margin: 0px 0 8px 0px !important; padding: 0px !important;">+91 832 674 1660</p>
//       </center>
//       <table cellpadding="5px" cellspacing="0">
//         <thead>
//           <tr>
//             <td>
//               <p style="font-size: 12px; font-weight: 700; padding: 0 0 3px 0; margin: 0;">ORDER DETAILS</p>
//               <span style="font-size: 10px;">
//                 INV NO: #${order?.order?.order_sequence}${order?.order?.order_no}<br>
//                 DATE: ${getFormattedDate()} <br>
//                 TIME: ${new Date(order?.order?.createdAt).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false })} <br />
//                 ITEM(s): ${order?.order?.orderItemsCount} Nos.
//               </span>
//             </td>
//             <td colspan="2">
//               <p style="font-size: 12px; font-weight: 700; padding: 0 0 3px 0; margin: 0;">CUSTOMER INFO</p>
//               ${customerInfo}
//             </td>
//           </tr>
//           <tr>
//             <td><b>${order?.order?.ordersType}</b></td>
//             <td colspan="2"><b>PAID</b></td>
//           </tr>
//           <tr>
//             <th colspan="3" style="border-top: 1px solid black;">TAX INVOICE</th>
//           </tr>
//           <tr style="">
//             <th style="text-align: left; border-bottom: 1px solid black; ">ITEM NAME</th>
//             <th style="text-align: center; border-bottom: 1px solid black;">QTY</th>
//             <th style="text-align: right; border-bottom: 1px solid black;">PRICE(Rs.)</th>
//           </tr>
//         </thead>
//         <tbody>
//           ${order?.orderItems
//             .map((item) => `
//               <tr>
//                 <td>
//                   ${item?.product?.name} <br>
//                   ${item?.addons && item?.addons?.length > 0 ? `
//                     <small>
//                       ${item?.addons
//                         .map((addon) => `
//                           - ${addon?.selectedValue} <br>
//                         `)
//                         .join('')}
//                     </small>
//                   ` : ''}
//                 </td>
//                 <td style="text-align: center; vertical-align: top">
//                   ${item?.quantity}
//                 </td>
//                 <td style="text-align: right; vertical-align: top">
//                   ${item?.rate?.toFixed(2)} <br>
//                   <small>
//                     ${item?.addons && item?.addons?.length > 0 ? `
//                       ${item?.addons
//                         .map((addon) => `
//                           + ${addon?.offerPrice?.toFixed(2)} <br>
//                         `)
//                         .join('')}
//                     ` : ''}
//                   </small>
//                 </td>
//               </tr>
//             `)
//             .join('')}
//         </tbody>
//         <tfoot>
//           <tr>
//             <td colspan="2" style="text-align: left;">
//               <b>SUB TOTAL</b><br>
//               <small>PLATFORM FEES</small><br>
//               <small style="display: ${order?.order?.packagingCharges > 0 ? 'block' : 'none'};">PACKAGING CHARGES</small><br>
//               <small>TAX (5%) <br>CGST: 2.5% (₹${taxDivide.toFixed(2)})<br></small>
//               <small>SGST: 2.5% (₹${taxDivide.toFixed(2)})</small>
//             </td>
//             <td>
//               <b>${order?.order?.totalTaxable?.toFixed(2)}</b><br>
//               <small>${order?.order?.platformFees?.toFixed(2)}</small><br>
//               <small style="display: ${order?.order?.packagingCharges > 0 ? 'block' : 'none'};">${order?.order?.packagingCharges?.toFixed(2)}</small><br>
//               <small>${(totalTaxAmount).toFixed(2)}</small>
//             </td>
//           </tr>
          
//           <tr style="display: ${order?.order?.deliveryCharges > 0 ? 'table-row' : 'none'};">
//             <td colspan="2" style="text-align: left;">
//               <small>DELIVERY CHARGES</small><br>
//             </td>
//             <td style="text-align: right;">
//               <small>${order?.order?.deliveryCharges.toFixed(2)}</small><br>
//             </td>
//           </tr>
//           <tr style="display: ${totalSizeUpgradeAmount > 0 ? 'table-row' : 'none'};">
//             <td colspan="2" style="text-align: left;">
//               <small>DISCOUNTS</small><br>
//               <small>Coupon: ${order?.order.coupon?.couponCode}</small>
//             </td>
//             <td style="text-align: right;">
//               <small>${totalSizeUpgradeAmount > 0 ? `- ${totalSizeUpgradeAmount.toFixed(2)}` : '0'}</small><br>
//             </td>
//           </tr>
//           <tr style="display: ${order?.order?.totalDiscount > 0 ? 'table-row' : 'none'};">
//             <td colspan="2" style="text-align: left;">
//               <small>DISCOUNTS</small><br>
//               <small>Coupon: ${order?.order.coupon?.couponCode}</small>
//             </td>
//             <td style="text-align: right;">
//               <small>- ${order?.order?.totalDiscount.toFixed(2)}</small><br>
//             </td>
//           </tr>
//           <tr>
//             <td colspan="2" style="text-align: left; border-top: 1px solid black;">
//               <b>GRAND TOTAL</b>
//             </td>
//             <td style="border-top: 1px solid black;">
//               <b>₹ ${order?.order?.grandTotal?.toFixed(2)}</b>
//             </td>
//           </tr>
//         </tfoot>
//       </table>
//       <center style="margin-top: 15px;">
//         ${qrCodeSvg}
//         <h2 style="font-size: 24px;">#${order?.order?.order_sequence}${order?.order?.order_no}</h2> 
//         <h3>THANK YOU.... VISIT AGAIN</h3>
//         <h5 style="margin: -5px 0px 0px 0px;">${new Date().toLocaleTimeString('en-US', {
//           hour: '2-digit',
//           minute: '2-digit',
//           second: '2-digit',
//           hour12: false,
//         })}</h5>
//       </center>
//       <div class="print-stop"></div>
//     </body>    
//     </html>
//     `;
//     const printIframe = document.createElement('iframe');
//     printIframe.style.display = 'none';
//     document.body.appendChild(printIframe);
//     const printDocument = printIframe.contentWindow.document;
//     printDocument.write(printContent);
//     printDocument.close();
//     printIframe.onload = () => {
//       setTimeout(() => {
//         document.body.removeChild(printIframe);
//       }, 1500);
//     };
//   };

//   const handleRiderClick = (rider) => {
//     setSelectedRider(rider);
//     // console.log("Rider", rider);
//   };

//   const handleSetAsDelivered = async () => {
//     try {
//       const response = await axios.put(
//         `${API.BASE_URL}order/deliver/previousOrder`,
//         {
//           "orderId": selectedOrder.order._id     
//           ,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       if (response.status === 200) {
//         toast.success("Order status updated successfully", {
//           autoClose: 3000,
//         });
//         await refreshOrdersData();
//       } else {
//         console.error('Error updating order status:', response.data);
//       }
//     } catch (error) {
//       console.error('Error updating order status:', error);
//     }
//   };
    
//   const totalSizeUpgradeAmount = useMemo(() => {
//     return selectedOrder?.orderItems?.reduce((acc, item) => {
//       return acc + (item?.sizeUpgradePayload?.newAddonItemOfferPrice || 0) - (item?.sizeUpgradePayload?.prevAddonItemOfferPrice || 0);
//     }, 0);
//   }, [selectedOrder?.orderItems]);
  
//   const totalTaxAmount = (selectedOrder?.order?.totalTaxAmount || 0) + (selectedOrder?.order?.GSTPackingCharges || 0) +  (selectedOrder?.order?.GSTPlatformFees || 0);
//   const taxDivide = totalTaxAmount / 2;

//   const formatAddress = (...parts) => parts.filter(Boolean).join(', ');

//   const userAddress= formatAddress(
//     selectedOrder?.order?.deliveryDetails?.addressLine1,
//     selectedOrder?.order?.deliveryDetails?.addressLine2,
//     selectedOrder?.order?.deliveryDetails?.landmark,
//     selectedOrder?.order?.deliveryDetails?.city,
//     selectedOrder?.order?.deliveryDetails?.state,
//     selectedOrder?.order?.deliveryDetails?.pincode
//   );

//   const [printedKOTOrders, setPrintedKOTOrders] = useState(getPrintedKOTOrders());

//   const handleKOTPrinted = (orderId) => {
//     setPrintedKOTOrders((prevPrintedOrders) => {
//       const updatedPrintedOrders = [...prevPrintedOrders, orderId];
//       return updatedPrintedOrders;
//     });
//   };

//   useEffect(() => {
//     localStorage.setItem('printedKOTOrders', JSON.stringify(printedKOTOrders));
//   }, [printedKOTOrders]);

//   const [idleTimer, setIdleTimer] = useState(IDLE_TIMEOUT);
//   const idleTimeout = useRef(null);

//   useEffect(() => {
//     const resetIdleTimer = () => {
//       clearTimeout(idleTimeout.current);
//       setIdleTimer(IDLE_TIMEOUT);

//       idleTimeout.current = setTimeout(() => {
//         document.getElementById("refresh").click();
//         setIdleTimer(IDLE_TIMEOUT);
//         resetIdleTimer();
//       }, IDLE_TIMEOUT * 1000);

//     };

//     const countdownInterval = setInterval(() => {
//       setIdleTimer((prev) => (prev > 0 ? prev - 1 : 0));
//     }, 1000);

//     const activityEvents = ["mousemove", "keydown", "click", "scroll", "touchstart"];
//     activityEvents.forEach((event) => {
//       window.addEventListener(event, resetIdleTimer);
//     });

//     resetIdleTimer();
//     return () => {
//       clearTimeout(idleTimeout.current);
//       clearInterval(countdownInterval);
//       activityEvents.forEach((event) => {
//         window.removeEventListener(event, resetIdleTimer);
//       });
//     };
//   }, []);
  
//   async function requestPrinterAccess() {
//     try {
//       const device = await navigator.usb.requestDevice({
//         filters: [{ vendorId: 0x1234 }], // Replace with your printer's vendorId
//       });
//       await device.open();
//       console.log("Printer connected:", device);
//       return device;
//     } catch (error) {
//       console.error("Failed to connect to the printer:", error);
//       return null;
//     }
//   }
  
//   return (
//     <>
//     <Box>
//       <Grid container className="flex-section">
//         <Grid className="ordersGrid" sx={{ backgroundColor: '#F6F6F6', marginTop: '90px', overflowY: 'auto', maxHeight: 'calc(100vh - 90px)', position: 'relative' }} item sm={6} md={6}>
//           <Box id="refresh" sx={{ opacity: 0, pointerEvents: "none", position: "fixed", bottom: 20, right: 20, backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "8px", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)" }}>
//             <Typography variant="body2" sx={{ fontWeight: "bold" }}>
//               Idle Timer: {idleTimer} seconds
//             </Typography>
//           </Box>
//           <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'fixed', width: '43.5%', zIndex: 10, backgroundColor: '#F6F6F6', boxShadow: isScrolled ? '0px 4px 0px rgba(0, 0, 0, 0.2)' : 'none', }}>
//             <Tabs
//               sx={{ justifyContent: "center", padding: '10px 10px 10px 10px', backgroundColor: '#F6F6F6' }}
//               value={currentTab}
//               onChange={handleChangeTab}
//               indicatorColor="secondary"
//               textColor="#1D7454"
//             >
//               <Tab sx={{ fontSize: '16px', justifyContent: "center", textTransform: 'capitalize' }} label="Pending" />
//               <Tab sx={{ fontSize: '16px', justifyContent: "center", textTransform: 'capitalize' }} label="Completed" />
//             </Tabs>
//             <Box>
//               <Button aria-controls="sort-menu" aria-haspopup="true" onClick={handleSortClick}>
//                 Sort By
//               </Button>
//               <Menu
//                 id="sort-menu"
//                 anchorEl={anchorElSort}
//                 keepMounted
//                 open={Boolean(anchorElSort)}
//                 onClose={handleSortClose}
//               >
//                 <MenuItem onClick={() => handleSortClose('false')}>Arrival Time</MenuItem>
//                 <MenuItem onClick={() => handleSortClose('true')}>Latest Orders</MenuItem>
//               </Menu>

//               {/* <Button aria-controls="filter-menu" aria-haspopup="true" onClick={handleFilterClick}>
//                 Filter By
//               </Button>
//               <Menu
//                 id="filter-menu"
//                 anchorEl={anchorElFilter}
//                 keepMounted
//                 open={Boolean(anchorElFilter)}
//                 onClose={handleFilterClose}
//               >
//                 <MenuItem onClick={() => handleFilterClose('ALL')}>All Orders</MenuItem>
//                 <MenuItem onClick={() => handleFilterClose('PICKUP')}>Pick Up</MenuItem>
//                 <MenuItem onClick={() => handleFilterClose('DELIVERY')}>Delivery</MenuItem>
//               </Menu> */}
//             </Box>
//           </Box>
//           <>
//             {orders.length === 0 ? (
//               <Box sx={{ minHeight: '100vh', overflow: 'auto', padding: '0 35px 0 35px', marginRight: '10px', marginTop: '10px', textAlign: 'end' }} className='custom-scrollbar'>
//                 <img src={Images.noOrders} alt='no-orders-available' style={{ marginTop: '50px' }} />
//                 <Typography variant="h5" sx={{ marginTop: '10px' }}>No orders available</Typography>
//               </Box>
//             ) : (
//               <Box sx={{ minHeight: '100vh', overflow: 'auto', padding: '0 15px 0 15px', marginRight: '0px', marginTop: '50px' }} className='custom-scrollbar'>
//                 <>
                 
//                   {currentTab === 0 && (
//                     <>
//                       {orders?.filter(order => order?.order?.orderStatus === 'PENDING' || order?.order?.orderStatus === 'CONFIRMED' || order?.order?.orderStatus === 'READY-TO-PICK').length > 0 ? (
//                         <OrderCard orders={orders?.filter(order => order?.order?.orderStatus === 'PENDING' || order?.order?.orderStatus === 'CONFIRMED' || order?.order?.orderStatus === 'READY-TO-PICK' )} onOrderClick={handleOrderClick} />
//                       ) : (
//                         <Box sx={{ minHeight: '100vh', overflow: 'auto', padding: '0 35px 0 35px', marginRight: '10px', textAlign: 'center' }} className='custom-scrollbar'>
//                           <img src={Images.noOrders} alt='no-orders-available' style={{ marginTop: '50px' }} />
//                           <Typography variant="h5" sx={{ marginTop: '10px' }}>No orders available</Typography>
//                         </Box>
//                       )}
//                     </>
//                   )}
//                   {currentTab === 1 && (
//                     <>
//                       {orders?.filter(order => order?.order?.orderStatus === 'COMPLETED' || order?.order?.orderStatus === 'DELIVERED' ).length > 0 ? (
//                         <OrderCard orders={orders?.filter(order => order?.order?.orderStatus === "COMPLETED" || order?.order?.orderStatus === 'DELIVERED')} onOrderClick={handleOrderClick} />
//                       ) : (
//                         <Box sx={{ minHeight: '100vh', overflow: 'auto', padding: '0 35px 0 35px', marginRight: '10px', textAlign: 'center' }} className='custom-scrollbar'>
//                           <img src={Images.noOrders} alt='no-orders-available' style={{ marginTop: '50px' }} />
//                           <Typography variant="h5" sx={{ marginTop: '10px' }}>No orders available</Typography>
//                         </Box>
//                       )}
//                     </>
//                   )}
//                 </>
//               </Box>
//             )}
//           </>
//         </Grid>
//         {orders?.length > 0 ? (
//           <Grid item sm={6} md={6} className='ordersGrid' sx={{ overflowY: 'auto', maxHeight: 'calc(110vh - 90px)' }}>
//             { selectedOrder ? (
//                 <> 
//                   <Box sx={{ marginTop: '100px', padding: '0 30px 0 30px'}}>
//                     <Grid container sx={{ borderBottom: '2px solid #000', padding: '0px 0 10px 0px', marginBottom: '10px'}}>
//                       <Grid item md={8}>
//                         <Typography sx={{ fontSize: '18px' }}>Order <span style={{ fontWeight: 'bold'}}> #{selectedOrder?.order?.order_sequence}{selectedOrder?.order?.order_no}</span></Typography>
//                         <Typography className='orderDetailsSmall'><span style={{ fontWeight: 'bold', paddingRight: '6px' }}>Order Type: </span> {selectedOrder?.order?.ordersType}</Typography>
//                         <Typography className='orderDetailsSmall' sx={{ fontWeight: '600' }}>Customer:</Typography>
//                         {selectedOrder?.order?.kioskUser ? 
//                           ( <Typography className='orderDetailsSmall'>{selectedOrder?.order?.kioskUser?.name}<br/>{selectedOrder?.order?.kioskUser?.phone}</Typography> ) : 
//                           ( <Typography className='orderDetailsSmall'>{selectedOrder?.order?.user?.name}<br/>{selectedOrder?.order?.user?.mobile}</Typography> )
//                         }
//                         {selectedOrder?.order?.ordersType === 'DELIVERY' && (
//                           <Typography className='orderDetailsSmall'>{userAddress}</Typography>
//                         )}
//                         {selectedOrder?.order?.ordersType === 'PICKUP' ? (
//                           <Typography className='orderDetailsSmall'>ARRIVAL TIME : {selectedOrder?.order?.readyTime}</Typography>
//                         ) : (
//                           <Typography className='orderDetailsSmall'>MAKING TIME : {selectedOrder?.order?.totalMakingTime}</Typography>
//                         )}
//                       </Grid>
//                       <Grid item md={4} sx={{ textAlign: 'end' }}>
//                         {qrCodeSvg && <div dangerouslySetInnerHTML={{ __html: qrCodeSvg }} />}
//                       </Grid>
//                     </Grid>
//                     <Grid container sx={{ borderBottom: '2px solid #000', padding: '0px 0 2px 0px'}}>
//                       <Grid item md={12}>
//                         <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>Order Summary</Typography>
//                         <Grid container sx={{ padding: '4px 0 4px 0'}}>
//                           <Grid item md={6}>
//                             <Typography className="onlineOrderFont" >
//                               Item(s)
//                             </Typography>
//                           </Grid>
//                           <Grid item md={6} sx={{ textAlign: 'end'}}>
//                             <Typography className='onlineOrderFont'>
//                               {selectedOrder?.order?.orderItemsCount}    
//                             </Typography>
//                           </Grid>
//                         </Grid>
//                         <Grid container sx={{ padding: '4px 0 4px 0'}}>
//                           <Grid item md={6}>
//                             <Typography className='onlineOrderFont'>
//                               Total taxable
//                             </Typography>
//                           </Grid>
//                           <Grid item md={6} sx={{ textAlign: 'end'}}>
//                             <Typography className='onlineOrderFont'>
//                               ₹ {selectedOrder?.order?.totalTaxable.toFixed(2)}  
//                             </Typography>
//                           </Grid>
//                         </Grid>
//                         <Grid container sx={{ padding: '4px 0 4px 0'}}>
//                           <Grid item md={6}>
//                             <Typography className='onlineOrderFont'>
//                               Total Tax
//                             </Typography>
//                           </Grid>
//                           <Grid item md={6} sx={{ textAlign: 'end'}}>
//                             <Typography className='onlineOrderFont'>
//                               ₹ {totalTaxAmount.toFixed(2)}  
//                             </Typography>
//                           </Grid>
//                         </Grid>
//                         {selectedOrder?.order?.ordersType === "DELIVERY" && (
//                           <>
//                             <Grid container sx={{ padding: '4px 0 4px 0' }}>
//                               <Grid item md={6}>
//                                 <Typography className='onlineOrderFont'>
//                                   Shipping
//                                 </Typography>
//                               </Grid>
//                               <Grid item md={6} sx={{ textAlign: 'end'}}>
//                                 <Typography className='onlineOrderFont'>
//                                   ₹ {selectedOrder?.order?.deliveryCharges.toFixed(2)}
//                                 </Typography>
//                               </Grid>
//                             </Grid>
//                             <Grid container sx={{ padding: '4px 0 4px 0' }}>
//                               <Grid item md={10}>
//                                 <Typography className='onlineOrderFont'>
//                                   Packaging Charges
//                                 </Typography>
//                               </Grid>
//                               <Grid item md={2} sx={{ textAlign: 'end'}}>
//                                 <Typography className='onlineOrderFont'>
//                                   ₹ {selectedOrder?.order?.packagingCharges?.toFixed(2)}
//                                 </Typography>
//                               </Grid>
//                             </Grid>
//                           </>
//                         )}
//                         <Grid container sx={{ padding: '4px 0 4px 0' }}>
//                           <Grid item md={6}>
//                             <Typography className='onlineOrderFont'>
//                               Platform Fees
//                             </Typography>
//                           </Grid>
//                           <Grid item md={6} sx={{ textAlign: 'end'}}>
//                             <Typography className='onlineOrderFont'>
//                               ₹ {selectedOrder?.order?.platformFees.toFixed(2)}
//                             </Typography>
//                           </Grid>
//                         </Grid>
//                         {selectedOrder?.order?.totalDiscount > 0 && (
//                           <Grid container sx={{ padding: '4px 0 4px 0' }}>
//                             <Grid item md={6}>
//                               <Typography className='onlineOrderFont'>
//                                 Discounts {selectedOrder?.order?.coupon && ( <Typography className="onlineOrderFont" sx={{ paddingBottom: '4px', color: '#1E9CED'}}>( {selectedOrder?.order?.coupon?.couponCode} )</Typography>)}
//                               </Typography>
//                             </Grid>
//                             <Grid item md={6} sx={{ textAlign: 'end' }}>
//                               <Typography className='onlineOrderFont' sx={{ color: 'green' }}>
//                                 - ₹ {selectedOrder?.order?.totalDiscount.toFixed(2)}
//                               </Typography>
//                             </Grid>
//                           </Grid>
//                         )}
//                         {totalSizeUpgradeAmount > 0 && (
//                           <Grid container sx={{ padding: '4px 0 4px 0' }}>
//                             <Grid item md={6}>
//                               <Typography className='onlineOrderFont'>
//                                 Discounts {selectedOrder?.order?.coupon && ( <Typography className="onlineOrderFont" sx={{ paddingBottom: '4px', color: '#1E9CED'}}>( {selectedOrder?.order?.coupon?.couponCode} )</Typography>)}
//                               </Typography>
//                             </Grid>
//                             <Grid item md={6} sx={{ textAlign: 'end'}}>
//                               <Typography className='onlineOrderFont' sx={{ color: 'green' }}>
//                                 {totalSizeUpgradeAmount > 0 ? `- ₹ ${totalSizeUpgradeAmount.toFixed(2)}` : ''}
//                               </Typography>
//                             </Grid>
//                           </Grid>
//                         )}
//                         <Grid container sx={{ padding: '6px 0 4px 0', borderTop: '1px solid #000'}}>
//                           <Grid item md={6}>
//                             <Typography sx={{ fontSize: '18px' }}>
//                               Total Payable
//                             </Typography>
//                           </Grid>
//                           <Grid item md={6} sx={{ textAlign: 'end'}}>
//                             <Typography sx={{ fontSize: '18px' }}>
//                               ₹ {selectedOrder?.order?.grandTotal?.toFixed(2)}
//                             </Typography>
//                           </Grid>
//                         </Grid>
//                       </Grid>
//                     </Grid>
//                     <Grid container sx={{ margin: '5px 0 8px 0', padding: '0px 0 2px 0px', overflowY: 'auto' }}>
//                       <Grid item sm={12} md={12} sx={{ overflow: 'auto'}}>
//                         <Grid container sx={{ padding: '10px 0px 4px 0'}}>
//                           <Grid item sm={11} md={11}>
//                             <Typography className="onlineOrderFont" >
//                               Item(s): <span style={{ fontWeight: '700' }}>{selectedOrder?.order?.numberOfItems}</span>
//                             </Typography>
//                           </Grid>
//                           <Grid item sm={1} md={1} sx={{ textAlign: 'center'}}>
//                             <Typography className='onlineOrderFont'>
//                               Qty                 
//                             </Typography>
//                           </Grid>
//                         </Grid>
//                         {selectedOrder?.orderItems?.map((item, index) =>{
//                           return(
//                             <Grid key={item._id} 
//                               onClick={(e) => { if (selectedOrder?.order?.orderStatus !== 'READY-TO-PICK') { e.stopPropagation(); }}}
//                               container sx={{ marginTop: '12px', marginBottom: '10px', borderRadius: '6px', border: '1px solid rgba(0, 0, 0, 0.1)', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' }} justifyContent="space-between"
//                             >
//                               <Grid item md={12} className='cursor-p' >
//                                 <Grid container sx={{ padding: '4px 0 4px 0'}}>
//                                   <Grid item sm={11} md={11} sx={{ display: 'flex', alignItems: 'center' }}>
//                                     <Typography className="onlineOrderFont" sx={{ paddingLeft: '10px' }} >
//                                       {item?.product?.name}  -  ₹ {item?.taxable?.toFixed(2)}
//                                     </Typography>
//                                   </Grid>
//                                   <Grid item sm={1} md={1} sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
//                                     <Typography className='onlineOrderFont' sx={{ paddingRight: '10px'}}>
//                                       {item?.quantity}           
//                                     </Typography>
//                                     {/* {currentTab === 0 && (
//                                         <Box
//                                           onClick={(e) => { if (selectedOrder?.order?.orderStatus !== 'READY-TO-PICK') { e.stopPropagation(); handleCheckboxChange(item?._id); }}}
//                                           sx={{ cursor: selectedOrder?.order?.orderStatus === 'READY-TO-PICK' ? 'not-allowed' : 'pointer', display: 'inline-flex', alignItems: 'center', paddingRight: '8px' }}
//                                         >
//                                           {item?.isReady ? ( <CheckBoxRounded/> ) : ( checkedItems[item._id] ? ( <CheckBoxRounded /> ) : ( <CheckBoxOutlineBlankOutlined /> ))}
//                                         </Box>
//                                     )} */}
//                                   </Grid>
//                                 </Grid>
//                                 {item?.addons && item?.addons?.length > 0 && (
//                                   <Grid container sx={{ paddingLeft: '10px', paddingBottom: '10px' }}>
//                                     {item?.addons?.map((addon) => (
//                                       <Grid item key={addon?._id} className='attributes'>{addon?.selectedValue}</Grid>
//                                     ))}
//                                   </Grid>
//                                 )}
//                               </Grid>
//                             </Grid>
//                           )
//                         })}
//                       </Grid>
//                     </Grid>
//                     {/* {getPrintedKOTOrders().includes(selectedOrder?.order._id) && (
//                       <Typography sx={{ fontSize: '14px', color: 'green', marginBottom: '10px' }}>
//                         KOT for this order has been printed
//                       </Typography>
//                     )} */}
//                     {printedKOTOrders.includes(selectedOrder.order._id) && (
//                       <p style={{ color: 'green', fontWeight: 'bold' }}>
//                         KOT for this order has been printed
//                       </p>
//                     )}
//                     <Grid container justifyContent={'space-around'} sx={{ marginTop: '15px', marginBottom: '15px' }}>
//                       {(selectedOrder?.order?.orderStatus !== 'DELIVERED' && selectedOrder?.order?.orderStatus !== 'COMPLETED') && (
//                         <>
//                           <Grid item sm={3} md={3}>
//                             <KOTPrinter orders={selectedOrder} orderDetail={selectedOrder.order} onKOTPrinted={handleKOTPrinted}  />
//                           </Grid>
//                           <Grid item sm={3} md={3}>
//                             <LabelPrinter orders={selectedOrder} orderDetail={selectedOrder.order} />
//                           </Grid>
//                           <Grid item sm={3} md={3}>
//                             {/* {selectedOrder?.order?.orderStatus === 'READY-TO-PICK' ? (
//                               <button
//                                 onClick={handleBillButtonClick}
//                                 className={`primary-button-container`}
//                               >
//                                 <span className='title'>Complete & Print Bill</span>
//                               </button>
//                             ) : (
//                               selectedOrder?.order?.ordersType === 'DELIVERY' ? (
//                                 <PrimaryButton disabledColor='#B8B8B8' disabledTitle={'Ready to pick'} onClick={handleReadyToPickForDelivery} spinner={false} title={'Ready to Pick'} style={{ }} />
//                               ) : (
//                                 <PrimaryButton disabledColor='#B8B8B8' disabledTitle={'Ready to pick'} onClick={handleReadyToPickClick} spinner={false} title={'Ready to Pick'} style={{ }} />
//                               )
//                             )} */}
//                             <PrimaryButton  disabledColor='#B8B8B8' disabledTitle={'Complete & Print Bill'} onClick={handleBillButtonClick} spinner={false} title={'Complete & Print Bill'} style={{ }} />
//                           </Grid>
//                         </>
//                       )}
//                       {(selectedOrder?.order?.orderStatus === 'COMPLETED' && selectedOrder?.order?.orderStatus !== 'DELIVERED') && (
//                         <>
//                           <Grid item sm={4}>
//                             <button
//                               onClick={handleBillButtonClick}
//                               className={`primary-button-container`}
//                             >
//                               <span className='title'>Reprint Bill</span>
//                             </button>
//                           </Grid>
//                           <Grid item sm={3} md={3}>
//                             <KOTPrinter orders={selectedOrder} orderDetail={selectedOrder.order} onKOTPrinted={handleKOTPrinted}  />
//                           </Grid>
//                           <Grid item sm={3} md={3}>
//                             <LabelPrinter orders={selectedOrder} orderDetail={selectedOrder.order} />
//                           </Grid>
//                         </>
//                       )}
//                     </Grid>
//                   </Box>
//                 </>
//             ) : (
//               <Box sx={{ margin: '120px 0 0 60px', padding: '0 30px 0 30px', textAlign: 'center', position: 'fixed', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
//                 <img src={Images.noProductSelected} alt='product-not-selcted' />
//                 <Typography variant="h5">Select an order for details</Typography>
//               </Box>
//             )}
//           </Grid>
//         ) : (
//           <Grid item sm={6} md={6} sx={{ backgroundColor: '#f6f6f6'}} >
//           </Grid>
//         ) }
//       </Grid>
//       <Dialog sx={{ borderRadius: '11px', '& .MuiDialog-paper': { overflowY: 'hidden', msOverflowStyle: 'none', scrollbarWidth: 'none', backgroundColor: '#ECECEC', '::-webkit-scrollbar': { display: 'none' } } }} open={openRiders} onClose={setCloseRidersModal}>
//         <Box className="riderModal" style={{ padding: '20px', overflowY: 'auto' }}>
//           <Typography className="title">Select Delivery Partner</Typography>
//           {ridersData?.map((rider) => (
//             <Box
//               key={rider?.id}
//               className={`riderBox ${selectedRider && selectedRider?.id === rider?.id ? 'selected' : ''} ${rider?.status === 'OFFLINE' ? 'disabled' : ''}`}
//               style={{ marginBottom: '20px', cursor: rider?.status === 'OFFLINE' ? 'not-allowed' : 'pointer'}}
//               onClick={() => rider?.status !== 'OFFLINE' && handleRiderClick(rider)}
//             >
//               <Box sx={{ display: 'flex', alignItems: 'center' }}>
//                 <img src={Images.profile} className="profileImage" />
//               </Box>
//               <Box>
//                 <Typography className="riderName">{rider?.name} ({rider?.city}, {rider?.state})</Typography>
//                 <img src={Images.reviews} />
//                 <Typography className="riderDetails"><strong style={{ paddingRight: '7px' }}>Mobile:</strong> {rider?.mobile}</Typography>
//                 <Typography className="riderDetails"><strong style={{ paddingRight: '7px' }}>Status:</strong> <span style={{ color: rider?.status === 'OFFLINE' ? 'red' : 'green' }}>{rider?.status}</span></Typography>
//               </Box>
//             </Box>
//           ))}
//         </Box>
//         <button disabled={riderPickLoading} onClick={handleReadyToPickClick} className="riderSelectButton" >{riderPickLoading ? <CircularProgress style={{ color: '#fff' }} size={24} /> : 'Send Order'}</button>
//       </Dialog>
//     </Box>
//     </>
//   );
// }

// export default OnlineOrders;