import { Box, Grid, Stack, Toolbar, Typography, Switch, Dialog, DialogContent, DialogTitle, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress, IconButton, Button, Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API } from '../../utils/Api';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { CloudDownloadOutlined, Visibility } from '@mui/icons-material';

function OrdersReport() {
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("role");
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.login.user); 
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('outletData')));
  const [orderReports, setOrderReports] = useState([]);
  const [allOrdersSort, setAllOrdersSort] = useState('true');
  const [openModal, setOpenModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [orderReportsData, setOrderReportsData] = useState(null);

  const handleOpenModal = (order) => {
    setSelectedOrder(order);
    setOpenModal(true);
  };
  
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedOrder(null);
  };

  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const day = today.getDate().toString().padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;

  const [selectedStartDate, setSelectedStartDate] = useState(formattedDate);
  const [selectedEndDate, setSelectedEndDate] = useState(formattedDate);

  const handleDateChange = (date) => {
    setSelectedStartDate(date);
    // console.log('date', date);
    setCurrentPage(1);
  };
  
  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    // console.log('date', date);
    setCurrentPage(1);
  };

  const getOrderReports = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API.BASE_URL}order/getOrdersForAdmin?startDate=${selectedStartDate}&endDate=${selectedStartDate}&outlet=${outletData._id}`,{
      // const response = await axios.get(`${API.BASE_URL}outlet/orders?allOrders=${allOrdersSort}&fromDate=${selectedStartDate}&toDate=${selectedEndDate}`,{
        headers: {
          Authorization: `Bearer ${token}`
        },
      }
      );
      const totalRecords = response?.data?.data?.totalOrdersData;
      console.log('totalRecords', response?.data?.data?.totalOrdersData);
      setOrderReportsData(response?.data?.data);
      setOrderReports(totalRecords);
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const totalPages = Math.ceil(orderReports.length / itemsPerPage);
  const paginatedOrderReports = orderReports.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    // Title
    const title = capitalizeWords('Orders Report');
    const textWidth = doc.getTextWidth(title);
    const titleX = (pageWidth - textWidth) / 2;
    doc.setFontSize(16);
    doc.text(title, titleX, 20);

    // Centered Date Range
    const leftMargin = 10;
    const dateRangeText = `From: ${selectedStartDate} To: ${selectedEndDate}`;
    const dateRangeWidth = doc.getTextWidth(dateRangeText);
    const dateRangeX = (pageWidth - dateRangeWidth) / 2 + leftMargin;
    doc.setFontSize(12);
    doc.text(dateRangeText, dateRangeX, 30);

    // Outlet information
    const outletName = capitalizeWords(outletData?.name);
    const outletAddress = capitalizeWords(outletData?.address || 'No Address Available');
    const outletContact = `${outletData?.email || 'No Email'}, ${outletData?.phone || 'No Phone Info'}`;

    doc.setFontSize(10);
    doc.text(`Outlet: ${outletName}`, 14, 40);
    doc.text(`Address: ${outletAddress}`, 14, 48);
    doc.text(`Contact: ${outletContact}`, 14, 56);

    // Column and table setup
    doc.setFontSize(12);
    const startY = 66;

    const columns = [
      { header: 'Order No', dataKey: 'orderNo' },
      { header: 'Customer', dataKey: 'customer' },
      { header: 'Coupon Code', dataKey: 'couponCode' },
      { header: 'Date', dataKey: 'date' },
      { header: 'Time', dataKey: 'time' },
      { header: 'Amount', dataKey: 'amount' },
    ];

    const rows = orderReports.map((order, index) => ({
      orderNo: `#${order?.order?.order_sequence}${order?.order?.order_no}`,
      customer: order?.order?.kioskUser
        ? `${order?.order?.kioskUser?.name}\n${order?.order?.kioskUser?.phone}`
        : `${order?.order?.user?.name}\n${order?.order?.user?.mobile}`,
      couponCode: order?.order?.coupon?.couponCode || 'N/A',
      date: new Date(order?.order?.createdAt).toLocaleDateString(),
      time: new Date(order?.order?.createdAt).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }),
      amount: `INR ${order?.order?.grandTotal?.toFixed(2)}`,
    }));

    doc.autoTable({
      columns,
      body: rows,
      startY: startY,
      theme: 'grid',
      styles: { halign: 'center' },
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 55, halign: 'left' }, 
        2: { cellWidth: 30 }, 
        3: { cellWidth: 20 },
        4: { cellWidth: 20 }, 
        5: { cellWidth: 35, halign: 'center', fontSize: 10 }, 
      },
    });

    doc.save(`Orders-Report.pdf`);
  };


  return (
    <>
      <Box className="dashBoard">
        <Toolbar />
        <Grid container justifyContent={'center'}>
          <Grid item md={11} flexDirection={'column'}>
            <Grid container md={12} justifyContent={'space-evenly'} sx={{ marginTop: '35px' }}>
              <Grid item md={12}>
                <Typography sx={{ fontSize: '18px', fontWeight: '600', margin: '16px 0 30px 40px', textAlign: 'center' }}>Customer Orders Report</Typography>
              </Grid>
              <Grid item md={2} textAlign={'center'}>
                <label>Date</label>
                <DatePicker
                  selected={selectedStartDate}
                  onChange={(date) => handleDateChange(format(date, 'yyyy-MM-dd'))}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select Date"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  customInput={
                    <FormControl
                      fullWidth
                      sx={{
                        backgroundColor: '#fff',
                        marginTop: '5px',
                        marginLeft: '5px',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#cecece',
                          borderWidth: '1px',
                        },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                          '&.Mui-focused fieldset': {
                            borderColor: '#cecece',
                          },
                        },
                        '& input': {
                          height: '40px',
                        },
                      }}
                    >
                      <input 
                        className="form-control" 
                        placeholder='Select Start Date' 
                        value={selectedStartDate ? format(new Date(selectedStartDate), 'yyyy-MM-dd') : ''} 
                        style={{ height: '56px', borderRadius: '10px', paddingLeft: '10px' }} 
                        readOnly 
                      />
                    </FormControl>
                  }
                />
              </Grid>
              {/* <Grid item md={2} textAlign={'center'}>
                <label>End Date</label>
                <DatePicker
                  selected={selectedEndDate}
                  onChange={(date) => handleEndDateChange(format(date, 'yyyy-MM-dd'))}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select End Date"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={100}
                  customInput={
                    <FormControl
                      fullWidth
                      sx={{
                        backgroundColor: '#fff',
                        marginTop: '5px',
                        marginLeft: '5px',
                        '& .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#cecece',
                          borderWidth: '2px',
                        },
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '10px',
                          '&.Mui-focused fieldset': {
                            borderColor: '#cecece',
                          },
                        },
                        '& input': {
                          height: '40px',
                        },
                      }}
                    >
                      <input 
                        className="form-control" 
                        placeholder='Select End Date' 
                        value={selectedEndDate ? format(new Date(selectedEndDate), 'yyyy-MM-dd') : ''} 
                        style={{ height: '56px', borderRadius: '10px', paddingLeft: '10px' }} 
                        readOnly 
                      />
                    </FormControl>
                  }
                />
              </Grid> */}
              <Grid item md={2} textAlign="center" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '25px' }}>
                <Button
                  variant="contained"
                  onClick={getOrderReports}
                  sx={{
                    padding: '10px 20px',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    borderRadius: '8px',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    textTransform: 'none',
                    backgroundColor: '#003049',
                    '&:hover': {
                      backgroundColor: 'darkcyan',
                      boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)',
                    },
                  }}
                >
                  Get Reports
                </Button>
              </Grid>
              {paginatedOrderReports.length > 0 && (
                <Grid item md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '25px' }}>
                  <CloudDownloadOutlined onClick={generatePDF} sx={{ fontSize: '45px', cursor: 'pointer', color: '#003049' }} />
                </Grid>
              )}
            </Grid>
            {loading ? (
              <Box sx={{ height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <CircularProgress /> 
                <Typography sx={{ paddingLeft: '10px' }}>Fetching Orders</Typography>
              </Box>
            ) : (
              <>
                { orderReports?.length === 0 ? (
                  <Typography sx={{ textAlign: 'center', padding: '20px', fontSize: '16px', color: '#555' }}>
                    No orders to display. Please select a date which you want and click "Get Reports".
                  </Typography>
                ) : ( 
                <>
                <Grid container md={userRole !== 'CASHIER' ? 11 : 11 } sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', borderRadius: '8px', padding: '0px 0px 0px 0px', margin: '21px 0px 25px 10px'}}>
                  <Grid item md={1} sx={{ padding: '20px 10px 10px 10px', borderRight: '1px solid grey'}}>
                    <Typography sx={{ textAlign: 'center', fontSize: '14px'}}>Order No</Typography>
                  </Grid>
                  <Grid item md={4} sx={{ padding: '20px 0px 10px 0px', borderRight: '1px solid grey'}}>
                    <Typography sx={{ textAlign: 'center', fontSize: '15px'}}>Customer Details</Typography>
                  </Grid>
                  <Grid item md={2} sx={{ padding: '20px 0px 10px 0px', borderRight: '1px solid grey'}}>
                    <Typography sx={{ textAlign: 'center', fontSize: '15px'}}>Order Amount</Typography>
                  </Grid>
                  <Grid item md={2} sx={{ padding: '20px 0px 10px 0px', borderRight: '1px solid grey' }}>
                    <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>Date</Typography>
                  </Grid>
                  <Grid item md={2} sx={{ padding: '20px 0px 10px 0px', borderRight: '1px solid grey' }}>
                    <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>Time</Typography>
                  </Grid>
                  <Grid item md={1} sx={{ padding: '20px 0px 10px 0px' }}>
                    <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>Detail</Typography>
                  </Grid>
                  <>
                    {orderReports?.length === 0 ? (
                      <Typography sx={{ textAlign: 'center', padding: '20px', fontSize: '16px' }}>
                        No matching products found.
                      </Typography>
                    ) : (
                      paginatedOrderReports?.map((order, index) => {
                        const isEvenRow = index % 2 === 0;
                        const backgroundColor = isEvenRow ? '#EDF9FF' : '#fff';
                        return (
                          <React.Fragment key={index}>
                            <Grid item md={1} sx={{ position: 'relative', backgroundColor, padding: '20px 10px 20px 10px', borderRight: '1px solid grey', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Typography sx={{ fontSize: '14px', fontWeight: 'bold', textTransform: 'capitalize' }}>
                                #{order?.order?.order_sequence}{order?.order?.order_no}
                              </Typography>
                            </Grid>
                            <Grid item md={4} sx={{ position: 'relative', backgroundColor, padding: '20px 10px 20px 20px', borderRight: '1px solid grey' }}>
                              <Typography sx={{ fontSize: '16px', fontWeight: '500', textTransform: 'capitalize' }}>
                                {order?.order?.kioskUser ? (
                                    <>
                                      {order?.order?.kioskUser?.name}<br />
                                      <span style={{ fontSize: '14px' }}>{order?.order?.kioskUser?.phone}</span>
                                    </>
                                    ) : (
                                    <>
                                      {order?.order?.user?.name}<br />
                                      <span style={{ fontSize: '14px' }}>{order?.order?.user?.mobile}</span>
                                    </>
                                )}
                              </Typography>
                            </Grid>
                            <Grid item md={2} sx={{ backgroundColor, padding: '20px 10px 20px 10px', borderRight: '1px solid grey', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Typography sx={{  fontSize: '15px', fontWeight: '500' }}>
                                ₹{order?.order?.grandTotal?.toFixed(2)}
                              </Typography>
                            </Grid>
                            <Grid item md={2} sx={{ backgroundColor, padding: '20px 10px 20px 10px', display: 'flex', justifyContent: 'center', borderRight: '1px solid grey', alignItems: 'center' }}>
                                <Typography sx={{  fontSize: '15px', fontWeight: '500' }}>
                                    {new Date(order.order.createdAt).toISOString().split('T')[0]}
                                </Typography>
                            </Grid>
                            <Grid item md={2} sx={{ backgroundColor, padding: '20px 10px 20px 10px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRight: '1px solid grey' }}>
                              <Typography sx={{  fontSize: '15px',  }}>
                                {new Date(order?.order?.createdAt).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })}
                              </Typography>
                            </Grid>
                            <Grid item md={1} sx={{ backgroundColor, padding: '20px 10px 20px 10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <Typography sx={{  fontSize: '15px',  }}>
                                <IconButton onClick={() => handleOpenModal(order)}><Visibility /></IconButton>
                              </Typography>
                            </Grid>
                          </React.Fragment>
                        );
                      })
                    )}
                  </>
                </Grid>
                <Typography sx={{ textAlign: 'center' }}> <strong>Order Total Amount:</strong> ₹{orderReportsData?.ordersGrandTotal}</Typography>
                </>
                )}
                {paginatedOrderReports.length > 0 && (
                  <Grid container justifyContent="center" sx={{ padding: '20px 0' }}>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                      variant="outlined"
                      shape="rounded"
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
          <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>Order Details</DialogTitle>
          <DialogContent dividers>
            {selectedOrder ? (
              <>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Typography variant="body1">
                    <strong>Order No: </strong> #{selectedOrder?.order?.order_sequence}{selectedOrder?.order?.order_no}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="body1">
                    <strong>User Name:</strong> {selectedOrder?.order?.kioskUser ? selectedOrder?.order?.kioskUser?.name : selectedOrder?.order?.user?.name}
                    </Typography>
                  </Grid>

                  <Grid item md={6}>
                    <Typography variant="body1">
                    <strong>Status:</strong>{' '}
                    <Box
                        component="span"
                        sx={{
                        backgroundColor: selectedOrder?.order?.status === 'CONFIRMED' ? 'green' : '#003049',
                        color: '#fff',
                        padding: '2px 10px',
                        borderRadius: '4px',
                        fontSize: '14px',
                        }}
                    >
                        {selectedOrder?.order?.orderStatus}
                    </Box>
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="body1">
                    <strong>Payment Status:</strong>{' '}
                    <Box
                        component="span"
                        sx={{
                        backgroundColor: selectedOrder?.order?.paymentStatus === 'PAID' ? 'green' : 'red',
                        color: '#fff',
                        padding: '2px 10px',
                        borderRadius: '4px',
                        fontSize: '14px',
                        }}
                    >
                        {selectedOrder?.order?.paymentStatus}
                    </Box>
                    </Typography>
                  </Grid>

                  <Grid item md={6}>
                    <Typography variant="body1">
                    <strong>Coupon Code: </strong> <span style={{ color: 'green', fontWeight: '500' }}> {selectedOrder?.order?.coupon?.couponCode || 'N/A'}</span>
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="body1">
                    <strong>Order Source: </strong> {selectedOrder?.order?.source || 'N/A'}
                    </Typography>
                  </Grid>

                  <Grid item md={6}>
                    <Typography variant="body1">
                    <strong>Mobile Number:</strong> {selectedOrder?.order?.kioskUser ? selectedOrder?.order?.kioskUser?.phone : selectedOrder?.order?.user?.mobile}
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Typography variant="body1">
                    <strong>Date & Time:</strong>{' '}
                    {new Date(selectedOrder?.order?.createdAt).toLocaleDateString()}, {' '}
                    {new Date(selectedOrder?.order?.createdAt).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    })}
                    </Typography>
                  </Grid>

                  <Grid item md={12} sx={{ marginTop: '10px' }}>
                    <Typography variant="h6" gutterBottom textAlign={'center'}>
                      Ordered Items
                    </Typography>
                    <Box component="table" sx={{ width: '100%', borderCollapse: 'collapse' }}>
                      <thead>
                      <tr>
                        <th style={{ padding: '8px', border: '1px solid #ccc' }}>Product</th>
                        <th style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'center' }}>Quantity</th>
                        <th style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'right' }}>Price</th>
                      </tr>
                      </thead>
                      <tbody>
                        {selectedOrder?.orderItems?.map((item, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td style={{ padding: '8px', border: '1px solid #ccc', verticalAlign: 'top' }}>
                              <strong>{item.product.name}</strong>
                              {item?.addons?.length > 0 && (
                                <ul style={{ paddingLeft: '20px', margin: 0, listStyleType: 'disc' }}>
                                {item.addons.map((addon, i) => (
                                    <li key={i}>{addon?.selectedValue}</li>
                                ))}
                                </ul>
                              )}
                              </td>
                              <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'center', verticalAlign: 'top' }}>
                                  {item?.quantity}
                              </td>
                              <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'right', verticalAlign: 'top' }}>
                              <div>
                                  ₹{item?.taxable?.toFixed(2)}<br/>
                                  {item.addons.map((addon, i) => (
                                      <li style={{ listStyle: 'none'}} key={i}>
                                          ₹{addon?.offerPrice}
                                      </li>
                                  ))}
                              </div>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                        <tr>
                          <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'right' }} colSpan={2}>
                            <strong style={{ marginRight: '30px' }}>Total Tax Amount</strong>
                          </td>
                          <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'right' }}>
                            <strong>₹{selectedOrder?.order?.totalTaxAmount?.toFixed(2)}</strong>
                          </td>
                        </tr> 
                        <tr>
                          <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'right' }} colSpan={2}>
                            <strong style={{ marginRight: '30px' }}>Total Discount</strong>
                          </td>
                          <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'right' }}>
                            <strong>₹{selectedOrder?.order?.totalDiscount?.toFixed(2)}</strong>
                          </td>
                        </tr> 
                        <tr>
                          <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'right' }} colSpan={2}>
                            <strong style={{ marginRight: '30px' }}>Order Total</strong>
                          </td>
                          <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'right' }}>
                            <strong>₹{selectedOrder?.order?.grandTotal?.toFixed(2)}</strong>
                          </td>
                        </tr>                        
                      </tbody>
                    </Box>
                  </Grid>
                  
                  <Grid item md={12} sx={{ marginTop: '0px' }}>
                    <Typography variant="h6" gutterBottom textAlign="center">
                      Order Status Logs
                    </Typography>
                    <Box component="table" sx={{ width: '100%', borderCollapse: 'collapse' }}>
                      <thead>
                        <tr>
                          <th style={{ padding: '8px', border: '1px solid #ccc' }}>Status</th>
                          <th style={{ padding: '8px', border: '1px solid #ccc' }}>Outlet</th>
                          <th style={{ padding: '8px', border: '1px solid #ccc' }}>Timestamp</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedOrder?.orderStatusLogs?.map((log, index) => (
                          <tr key={index}>
                            <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'center' }}>
                              {typeof log?.orderStatus === 'string' ? log.orderStatus : 'N/A'}
                            </td>
                            <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'center' }}>
                              {log?.outlet?.name || 'N/A'}
                            </td>
                            <td style={{ padding: '8px', border: '1px solid #ccc', textAlign: 'center' }}>
                              {log?.createdAt ? (
                                `${new Date(log.createdAt).toLocaleDateString()}, ${new Date(log.createdAt).toLocaleTimeString('en-US', {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  hour12: true,
                                })}`
                              ) : (
                                'N/A'
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Box>
                  </Grid>

                </Grid>
              </>
            ) : (
              <Typography>No details available</Typography>
            )}
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}

export default OrdersReport;