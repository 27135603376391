import React, { useEffect, useRef, useState } from 'react';
import logo from '../assets/images/xpanceLabel.png';
import PrimaryButton from './PrimaryButton';
import { toast } from 'react-toastify';

const pageStyle = `
  @page {
    size: 50mm 75mm;
    margin: 0;
  }

  body {
    text-transform: uppercase;
  }

  @media all {
    .pageBreak {
      display: none;
    }
  }

  @media print {
    body {
      font-family: "Courier Prime", monospace;
    }
    .bodyBox{
      padding: 10px !important;
      page-break-before: always; 
    }
    .orderNoAndTime{
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding-bottom: 4px;
    }
    .orderNo{
      font-weight: 800;
      margin: 0;
      font-size: 20px;
    }
    .readyTime{
      font-weight: 800;
      margin: 0;
      font-size: 20px;
      text-align: right;
    }
    .productName{
      font-size: 14px;
      font-weight: 800;
      margin: 0;
    }
    ul {
      margin: 0;
      padding: 5px;
    }
    li {
      font-size: 12px;
      margin: 0;
    }
    .pageBreak {
      page-break-before: always;
    }
  }
`;

const ContentToPrint = React.forwardRef(
  ({ productName, addons, orderNo, orderSequence, readyTime, ordersType, takeAway }, ref) => {
    return (
      <div className='bodyBox' ref={ref}>
        <div>
          <div className='orderNoAndTime'>
            <p className='orderNo'>#{orderSequence}{orderNo}</p>
            <p className='readyTime'>
              ({takeAway === 'TAKE-AWAY' ? 'T' : ordersType === 'PICKUP' ? 'P' : ordersType === 'DELIVERY' ? 'D' : ''})
            </p>
            {/* <p className='readyTime'>{readyTime}</p> */}
          </div>
          <p className='productName'>{productName}</p>
          {addons && addons?.length > 0 && (
            <ul>
              {addons?.map((addon, index) => (
                <li key={index}> {addon?.selectedValue}</li>
              ))}
            </ul>
          )}
          <p className='readyTime'>{readyTime}</p>
        </div>
      </div>
    );
  }
);

const LabelPrinter = ({ orders, orderDetail }) => {
  const contentRefs = useRef([]);
  const [dataArr, setDataArr] = useState([]);

  // console.log('Received orders:', orders);

  // useEffect(() => {
  //   if (orders) {
  //     console.log('Received orders:', orders);
  //     const updatedDataArr = [];
  //     orders?.orderItems.forEach((orderItem) => {
  //       for (let i = 0; i < orderItem.quantity; i++) {
  //         updatedDataArr.push(orderItem);
  //       }
  //     });
  //     setDataArr(updatedDataArr);
  //   }
  // }, [orders]);

  useEffect(() => {
    if (orders) {
      // console.log('Received orders:', orders);
      const sortedOrderItems = orders.orderItems.sort((a, b) => {
        if (a.product.classType === 'BEVERAGE' && b.product.classType !== 'BEVERAGE') {
          return -1;
        }
        if (a.product.classType !== 'BEVERAGE' && b.product.classType === 'BEVERAGE') {
          return 1;
        }
        return 0;
      });
  
      const updatedDataArr = [];
      sortedOrderItems.forEach((orderItem) => {
        for (let i = 0; i < orderItem.quantity; i++) {
          updatedDataArr.push(orderItem);
        }
      });
      setDataArr(updatedDataArr);
    }
  }, [orders]);
  
  const printContent = () => {
    // toast.success("KOT printed!", { autoClose: 3000 });
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    const printDocument = iframe.contentWindow || iframe.contentDocument;
    const doc = printDocument.document || printDocument;
    doc.open();
    doc.write('<html><head><style>');
    doc.write(pageStyle);
    doc.write('</style></head><body>');
    dataArr.forEach((order, index) => {
      const content = contentRefs.current[index];
      doc.write(content.outerHTML);
      if (index < dataArr.length - 1) {
        doc.write('<div class="pageBreak"></div>');
      }
    });
    doc.write('</body></html>');
    doc.close();
    iframe.focus();
    iframe.contentWindow.print();
  };

  return (
    <>
      <div style={{ display: 'none' }}>
        {dataArr.map((order, index) => (
          <ContentToPrint
            key={index}
            ref={(ref) => (contentRefs.current[index] = ref)}
            productName={order.product.name}
            addons={order.addons}
            orderNo={orderDetail.order_no}
            orderSequence={orderDetail.order_sequence}
            readyTime={orderDetail.readyTime}
            orderSerialNo={order.orderSerialNo}
            ordersType={orderDetail.ordersType}
            takeAway={orderDetail.pickupOption}
          />
        ))}
      </div>
      <PrimaryButton onClick={printContent} disabledTitle={'Printing'} title={'Print Label'} style={{ borderRadius: '30px' }} />
    </>
  );
};

export default LabelPrinter;
