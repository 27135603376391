import { Box, Grid, TextField, Toolbar, Typography, InputAdornment, IconButton } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { API } from '../../utils/Api';
import PrimaryButton from '../../components/PrimaryButton';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { VisibilityOff, Visibility } from '@mui/icons-material';

const Profile = () => {
  const token = localStorage.getItem('token');
  const [profile, setProfile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCpassword] = useState('');

  const getUserProfile = async () => {
    setLoading(true);
    try {
      console.log('token', token);
      // setLoading(true);
      const response = await axios.get(`${API.BASE_URL}outlet/user/profile`,{
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      setProfile(response.data.data);
      console.log('Profile fetched successfully:', response.data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, [])

  const schema = Yup.object().shape({
    oldPassword: Yup.string().required('Old password is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(/(?=.*[a-z])/, 'Password must include at least one lowercase letter')
      .matches(/(?=.*[A-Z])/, 'Password must include at least one uppercase letter')
      .matches(/(?=.*\d)/, 'Password must include at least one digit')
      .matches(/(?=.*[@$!%*?&])/, 'Password must include at least one special character')
      .min(8, 'Password must be at least 8 characters long')
      .max(12, 'Password must be at most 12 characters long')
      .notOneOf([Yup.ref('oldPassword')], 'New password must be different from the old password'),
    cpassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
  });

  const handleInputChange = (field, value) => {
    if (field === 'oldPassword') {
      setOldPassword(value);
    } else if (field === 'password') {
      setPassword(value);
    } else if (field === 'cpassword') {
      setCpassword(value);
    }
    setErrors({ ...errors, [field]: '' });
  };

  const validateForm = async () => {
      try {
          await schema.validate({ oldPassword, password, cpassword }, { abortEarly: false });
          return true; // Form is valid
      } catch (error) {
          if (error instanceof Yup.ValidationError) {
              const newErrors = {};
              error.inner.forEach((err) => {
                  newErrors[err.path] = err.message;
              });
              setErrors(newErrors);
          }
          return false; // Form is invalid
      }
  };

  const handleButtonClick = async () => {
      const isValid = await validateForm();
      if (isValid) {
        setBtnDisabled(true);
        try {
          const response = await axios.put(`${API.BASE_URL}outlet/user/change-password`,
          {
            "oldPassword": oldPassword,
            "newPassword": cpassword
          }, {
            headers: { Authorization: `Bearer ${token}` }
          }); 
          if ( response.data.status === true ) {
            toast.success("Password changed successfully!!!", {
              autoClose: 3000,
            });
          }
        } catch (error) {
            console.error('Error:', error?.response?.data);
            let errorMessage =  error?.response?.data?.error || error?.response?.data?.data?.message;
            toast.error( errorMessage , {
              autoClose: 3000,
            });
        } finally {
          setBtnDisabled(false);
        }
      }
  };

  const handleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };
  
  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCPasswordVisibility = () => {
    setShowCPassword(!showCPassword);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: '20px' }}>
      <Toolbar />
      <Toolbar />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={{ padding: '20px', border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
            <Typography variant='h4' sx={{ paddingBottom: '15px' }}>Your Details</Typography>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant='h5' sx={{ fontWeight: '600' }}>
                  Name: 
                  <span style={{ fontWeight: '400', marginLeft: '10px' }}>{profile?.user?.name}</span>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='h5' sx={{ fontWeight: '600' }}>
                  Mobile: 
                  <span style={{ fontWeight: '400', marginLeft: '10px' }}>{profile?.user?.mobile}</span>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='h5' sx={{ fontWeight: '600' }}>
                    Email: 
                    <span style={{ fontWeight: '400', marginLeft: '10px' }}>{profile?.user?.email}</span>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant='h5' sx={{ fontWeight: '600' }}>
                  Cashier Id: 
                  <span style={{ fontWeight: '400', marginLeft: '10px' }}>{profile?.user?.idNumber}</span>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ padding: '20px', border: '1px solid #ddd', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
            <Typography variant='h4' sx={{ paddingBottom: '15px' }}>Change your password</Typography>
            <Box sx={{ textAlign: 'left', marginTop: '21px' }}>
              <label style={{  }}>Your Old Password</label>
              <TextField
                fullWidth 
                id="oldPassword" 
                sx={{ borderRadius: '8px', backgroundColor: '#fff' }} 
                type={showOldPassword ? 'text' : 'password'}
                placeholder='Enter' 
                variant="outlined" 
                value={oldPassword}
                onChange={(e) => handleInputChange('oldPassword', e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleOldPasswordVisibility} edge="end">
                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.oldPassword}                                                    
              />
              {errors.oldPassword && (
                <Typography className="errorText" color="error">
                  {errors.oldPassword}
                </Typography>
              )}
            </Box>
            <Box sx={{ textAlign: 'left', marginTop: '21px' }}>
              <label style={{  }}>Enter New Password</label>
              <TextField
                fullWidth 
                id="password" 
                sx={{ borderRadius: '8px', backgroundColor: '#fff' }} 
                type={showPassword ? 'text' : 'password'}
                placeholder='Enter' 
                variant="outlined" 
                value={password}
                onChange={(e) => handleInputChange('password', e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={handlePasswordVisibility} edge="end">
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                error={!!errors.password}                                                    
              />
              {errors.password && (
                <Typography className="errorText" color="error">
                  {errors.password}
                </Typography>
              )}
            </Box>
            <Box sx={{ textAlign: 'left', marginTop: '21px' }}>
              <label style={{  }}>Confirm New Password</label>
              <TextField
                fullWidth 
                id="cpassword" 
                sx={{ borderRadius: '8px', backgroundColor: '#fff' }} 
                type={showCPassword ? 'text' : 'password'}
                placeholder='Enter' 
                variant="outlined" 
                value={cpassword}
                onChange={(e) => handleInputChange('cpassword', e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleCPasswordVisibility} edge="end">
                        {showCPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!errors.cpassword}                                                    
              />
              {errors.cpassword && (
                <Typography className="errorText" color="error">
                  {errors.cpassword}
                </Typography>
              )}
            </Box>
            <Grid container justifyContent={'center'} style={{ marginTop: '27px'}}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <PrimaryButton title={'Reset Password'} onClick={handleButtonClick} spinner={true} disabledTitle={'Sending'} style={{ borderRadius: '30px', fontSize: '18px' }} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Profile;
