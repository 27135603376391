import { Box, Toolbar, Typography, TextField, Grid, InputAdornment, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Images from '../../utils/Images';
import PrimaryButton from '../../components/PrimaryButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { API } from '../../utils/Api';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useTheme } from '@emotion/react';

function ResetPassword() {
    const navigate = useNavigate();
    const location = useLocation();
    const [password, setPassword] = useState('');
    const [cpassword, setCpassword] = useState('');
    const [errors, setErrors] = useState({});
    const [apiError, setApiError] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [userId, setUserId] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showCPassword, setShowCPassword] = useState(false);
    const theme = useTheme();

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const id = query.get('_id');
        if (id) {
            setUserId(id);
            console.log('Captured Id', id);
        }
    }, [location.search]);

    const schema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
            .matches(/(?=.*[a-z])/, 'Password must include at least one lowercase letter')
            .matches(/(?=.*[A-Z])/, 'Password must include at least one uppercase letter')
            .matches(/(?=.*\d)/, 'Password must include at least one digit')
            .matches(/(?=.*[@$!%*?&])/, 'Password must include at least one special character')
            .min(8, 'Password must be at least 8 characters long')
            .max(12, 'Password must be at most 12 characters long'),
        cpassword: Yup.string()
            .required('Confirm your password')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    const handleInputChange = (field, value) => {
        if (field === 'password') {
            setPassword(value);
        } else if (field === 'cpassword') {
            setCpassword(value);
        }
        setErrors({ ...errors, [field]: '' });
    };

    const validateForm = async () => {
        try {
            await schema.validate({ password, cpassword }, { abortEarly: false });
            return true; // Form is valid
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                const newErrors = {};
                error.inner.forEach((err) => {
                    newErrors[err.path] = err.message;
                });
                setErrors(newErrors);
            }
            return false; // Form is invalid
        }
    };

    const handleButtonClick = async () => {
        const isValid = await validateForm();
        if (isValid) {
        setBtnDisabled(true);
        try {
            const response = await axios.put(`${API.BASE_URL}outlet/user/reset-password`,
            {
                "user": userId,
                "password": cpassword
            }); 
            if ( response.data.status === true ) {
                toast.success("Password changed successfully!!!", {
                    autoClose: 3000,
                });
                setTimeout(() => {
                    navigate('/');
                }, 1000);
            }
        } catch (error) {
            console.error('Error:', error);
            let errorMessage =  error?.response?.data?.data?.error || "Try again later";
            toast.error( errorMessage , {
                autoClose: 3000,
            });
        } finally {
            setBtnDisabled(false);
        }
        }
    };

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleCPasswordVisibility = () => {
        setShowCPassword(!showCPassword);
    };

    return (
    <>
        <Box sx={{ textAlign: 'center' }}>
            <Grid container justifyContent='center'>
                <Grid item md={4} sx={{ padding: '0', [theme.breakpoints.down('md')]: { padding: '0 40px 0 40px' }}}>
                    <Toolbar />
                    <Toolbar />
                    <img src={Images.xpanse} />
                    <Typography variant='h5' sx={{ margin: '21px 0 21px 0' }}>Enter Your Password</Typography>
                    <Box sx={{ textAlign: 'left' }}>
                        <label style={{  }}>New Password</label>
                        <TextField
                            fullWidth 
                            id="password" 
                            sx={{ borderRadius: '8px', backgroundColor: '#fff' }} 
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Enter' 
                            variant="outlined" 
                            value={password}
                            onChange={(e) => handleInputChange('password', e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handlePasswordVisibility} edge="end">
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={!!errors.password}                                                    
                        />
                        {errors.password && (
                            <Typography className="errorText" color="error">
                                {errors.password}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ textAlign: 'left', marginTop: '21px' }}>
                        <label style={{  }}>Confirm New Password</label>
                        <TextField 
                            fullWidth 
                            id="cpassword" 
                            type={showCPassword ? 'text' : 'password'}
                            sx={{ borderRadius: '8px', backgroundColor: '#fff' }} 
                            placeholder='Enter' 
                            variant="outlined" 
                            value={cpassword}
                            onChange={(e) => handleInputChange('cpassword', e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleCPasswordVisibility} edge="end">
                                            {showCPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={!!errors.cpassword}
                        />
                        {errors.cpassword && (
                            <Typography className="errorText" color="error">
                                {errors.cpassword}
                            </Typography>
                        )}
                    </Box>
                    <Grid container justifyContent={'center'} style={{ marginTop: '27px'}}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <PrimaryButton title={'Reset Password'} disabled={btnDisabled} onClick={handleButtonClick} spinner={true} disabledTitle={'Sending'} style={{ borderRadius: '30px', fontSize: '18px' }} />
                        </Grid>
                    </Grid>
                </Grid>    
            </Grid>
        </Box>
    </>
    );
}

export default ResetPassword;