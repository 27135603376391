import { CheckCircle } from '@mui/icons-material';
import { Box, Grid, Typography, Collapse, Slide, Grow } from '@mui/material';
import React, { useEffect, useState } from 'react';

function OrderCard({ orders, onOrderClick }) {

    const initialExpandedOrderId = orders?.length > 0 ? orders[0]?.order?._id : null;
    const [expandedOrderId, setExpandedOrderId] = useState();
    const [openedOrders, setOpenedOrders] = useState(() => {
        const saved = localStorage.getItem('openedOrders');
        return saved ? new Set(JSON.parse(saved)) : new Set();
    });

    useEffect(() => {
        localStorage.setItem('openedOrders', JSON.stringify([...openedOrders]));
    }, [openedOrders]);

    // const handleCardClick = (orderId) => {
    //     console.log('Clicked order ID:', orderId);
    //     setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
    //     setOpenedOrders(prev => new Set(prev.add(orderId)));
    //     onOrderClick(orderId);
    // };
    const handleCardClick = (orderId) => {
        console.log('Clicked order ID:', orderId);
        // Toggle expansion: if the order is already expanded, collapse it (set to null)
        if (expandedOrderId === orderId) {
            setExpandedOrderId(null);
            onOrderClick(null);  // Pass null to indicate no order is selected
        } else {
            setExpandedOrderId(orderId);
            setOpenedOrders(prev => new Set(prev.add(orderId)));
            onOrderClick(orderId);  // Pass the new order ID
        }
    };

    return (
        <>
            <Box>
                {orders?.map((order) => (
                    <div
                        key={order.order._id}
                        sx={{ backgroundColor: '#F6F6F6', cursor: 'pointer', margin: '10px', borderRadius: '8px' }}
                        onClick={() => handleCardClick(order?.order?._id)}
                    >
                        {expandedOrderId === order?.order?._id ? (
                            <ExpandedView order={order} />
                        ) : (
                            <CollapsedView order={order} hasBeenOpened={openedOrders.has(order.order._id)} />
                        )}
                    </div>
                ))}
            </Box>
        </>
    );
}

const CollapsedView = ({ order, hasBeenOpened }) => {
    const formattedGrandTotal = order?.order?.grandTotal?.toFixed(2);
    const dateString = order?.order?.createdAt;
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;
    const orderStatus = order?.order?.orderStatus;
    const shouldShowNewTag = !hasBeenOpened && orderStatus !== 'DELIVERED' && orderStatus !== 'COMPLETED';

    return (
        <Box className="cursor-p" sx={{ backgroundColor: shouldShowNewTag ? '#E0F7FA' : '#fff', color: '#000', padding: '13px 17px 13px 17px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', margin: '10px', marginTop: '18px', borderRadius: '8px' }}>
            <Grid container justifyContent="space-between" sx={{ borderBottom: '1px solid #000', paddingBottom: '7px', marginBottom: '14px'}}>
                <Grid item sx={{ display: 'flex' }}>
                    <Typography className='onlineOrderTitleFont' sx={{ fontWeight: '800' }}>
                        #{order?.order?.order_sequence}{order?.order?.order_no}
                    </Typography>
                    {shouldShowNewTag && (
                        <Box component="span" sx={{ backgroundColor: '#FF6347', color: '#fff', fontSize: '10px', padding: '4px 15px 4px 15px', borderRadius: '12px', marginLeft: '10px' }}>
                            New
                        </Box>
                    )}
                    {order?.order?.kioskUser && (
                        <Box component="span" sx={{ backgroundColor: '#F77F00', color: '#fff', fontSize: '10px', fontWeight: '800', padding: '4px 15px 4px 15px', borderRadius: '12px', marginLeft: '10px' }}>
                            KIOSK
                        </Box>
                    )}
                </Grid>
                <Grid item>
                    <Typography className='onlineOrderFont' sx={{ fontWeight: '800'}}>{order?.order?.orderItemsCount} Item(s)</Typography>
                </Grid>
                <Grid item>
                    <Typography className='onlineOrderTitleFont' sx={{ fontWeight: '800' }}>
                        {/* {formattedTime} */}
                        {order?.order?.ordersType === 'PICKUP' ? order?.order?.readyTime : order?.order?.readyTime}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
                <Grid item md={8}>
                    <Grid container sx={{ padding: '4px 0 4px 0'}}>
                        <Grid item md={7}>
                            <Typography className="onlineOrderFont opacityText" sx={{ fontWeight: '600' }} >
                                {order?.order?.ordersType}
                            </Typography>
                        </Grid>
                        <Grid item md={4}>
                            <Typography className='onlineOrderTitleFont' sx={{ fontWeight: '600' }}>{order?.order?.orderStatus}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4} sx={{ textAlign: 'right' }}>
                    <Typography className='onlineOrderTitleFont' sx={{ fontWeight: '800', color: 'green' }}>
                     ₹ {formattedGrandTotal}
                    </Typography>
                    {/* <Box className='onlineOrderTitleFont' sx={{ backgroundColor: order?.order?.paymentStatus === 'PAID' ? '#36CD1D' : '#FB9000', color: '#003049', padding: '1px 20px 1px 20px', borderRadius: '30px', marginLeft: '10px' }}>
                        {order?.order?.paymentStatus === 'PAID' ? 'Paid' : 'Not Paid'}                    
                    </Box> */}
                </Grid>
            </Grid>
        </Box>
    );
};

const ExpandedView = ({ order }) => {
    const formattedGrandTotal = order?.order?.grandTotal?.toFixed(2);
    const dateString = order?.order?.createdAt;
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const formattedTime = `${hours}:${minutes}`;

    return (
        <Grow in={true} timeout={300} appear>
            <Box className="cursor-p" sx={{ backgroundColor: '#003049', color: '#fff', padding: '13px 17px 13px 17px', margin: '10px', marginTop: '18px', borderRadius: '8px' }}>
            <Grid container justifyContent="space-between" sx={{ borderBottom: '1px solid #fff', paddingBottom: '7px', marginBottom: '14px'}}>
                <Grid item>
                    <Typography className='onlineOrderTitleFont' sx={{ fontWeight: '800' }}>
                        Order #{order?.order?.order_sequence}{order?.order?.order_no}
                    </Typography>
                </Grid>
                <Grid item>
                    {order?.order?.kioskUser && (
                        <Box component="span" sx={{ backgroundColor: '#A0D2DB', color: '#fff', fontSize: '14px', fontWeight: '800', padding: '5px 25px 4px 25px', borderRadius: '50px', marginTop: '-14px' }}>
                            KIOSK
                        </Box>
                    )}
                </Grid>
                <Grid item>
                <Typography className='onlineOrderTitleFont' sx={{ fontWeight: '800' }}>
                    {/* {formattedTime} */}
                    <span style={{ padding: '0 10px 0 0'}}>{order?.order?.ordersType === 'DELIVERY' ? 'Ready Time' : 'Pick Up Time'}</span>  
                    {order?.order?.ordersType === 'PICKUP' ? order?.order?.readyTime : order?.order?.readyTime}
                </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
                <Grid item md={8}>
                    <Grid container sx={{ padding: '4px 0 4px 0'}}>
                        <Grid item md={6} sx={{ display: 'flex'}} >
                            <Typography className="onlineOrderFont opacityText" >
                                Products
                            </Typography>
                            <Typography sx={{ paddingLeft: '20px', fontWeight: '800' }} className='onlineOrderFont'>
                                {order?.orderItems?.length}               
                            </Typography>
                        </Grid>
                        <Grid item md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography className="onlineOrderFont opacityText" >
                                Quantity
                            </Typography>
                            <Typography sx={{ paddingLeft: '20px', fontWeight: '800'  }} className='onlineOrderFont'>
                                {order?.order?.orderItemsCount}               
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ padding: '4px 0 4px 0'}}>
                        <Grid item md={7}>
                            <Typography className='onlineOrderFont  opacityText'>
                                Order Type
                            </Typography>
                        </Grid>
                        <Grid item md={4}>
                            <Typography className='onlineOrderFont' sx={{ fontWeight: '800' }}>
                                {order?.order?.ordersType}    
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ padding: '4px 0 4px 0'}}>
                        <Grid item md={7}>
                            <Typography className='onlineOrderFont opacityText'>
                                Order Time
                                {/* {order?.order?.ordersType === 'DELIVERY' ? 'Ready Time' : 'Pick Up Time'} */}
                            </Typography>
                        </Grid>
                        <Grid item md={4}>
                            <Typography className='onlineOrderFont' sx={{ fontWeight: '800' }}>
                                {formattedTime}
                                {/* {order?.order?.ordersType === 'PICKUP' ? order?.order?.readyTime : order?.order?.totalMakingTime} */}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4}>
                    {/* <Grid container sx={{  }}> */}
                    <Typography className='onlineOrderTitleFont' sx={{ marginTop: '60px', fontWeight: '800', textAlign: 'right' }}>
                        ₹ {formattedGrandTotal}
                    </Typography>
                        {/* <Box className='onlineOrderTitleFont' sx={{ backgroundColor: order?.order?.paymentStatus === 'PAID' ? '#36CD1D' : '#FB9000', color: '#003049', padding: '1px 20px 1px 20px', borderRadius: '30px', marginLeft: '10px' }}>
                            {order?.order?.paymentStatus === 'PAID' ? 'Paid' : 'Not Paid'}                    
                        </Box> */}
                    {/* </Grid> */}
                </Grid>
            </Grid>
            </Box>
        </Grow>
    );
};

export default OrderCard;