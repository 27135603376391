// store.js
import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './Login/loginSlice';

// Retrieve user data from localStorage
const userDataFromStorage = JSON.parse(localStorage.getItem('userData'));

const initialState = {
  login: {
    isLoggedIn: userDataFromStorage ? true : false,
    user: userDataFromStorage || null,
  },
};

const store = configureStore({
  reducer: {
    login: loginReducer,
  },
  preloadedState: initialState, // Set the preloaded state
});

export default store;