import { Box, Toolbar, Typography, TextField, Grid, IconButton, InputAdornment } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Images from '../../utils/Images';
import PrimaryButton from '../../components/PrimaryButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as Yup from 'yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { API } from '../../utils/Api';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../../store/Login/loginSlice';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [cashier, setCashier] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const message = location?.state?.message;

  useEffect(() => {
    if (message === true) {
      console.log("Message is true, reloading page...");
      // Clears the state by navigating to the same route without the 'message' state
      navigate(location.pathname, { replace: true, state: {} });
      window.location.reload();
    }
  }, [navigate, location]);

  const schema = Yup.object().shape({
    cashier: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (field, value) => {
    if (field === 'cashier') {
      setCashier(value);
    } else if (field === 'password') {
      setPassword(value);
    }
    setErrors({ ...errors, [field]: '' });
  };

  const validateForm = async () => {
    try {
      await schema.validate({ cashier, password }, { abortEarly: false });
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const newErrors = {};
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
        setErrors(newErrors);
      }
      return false;
    }
  };

  const handleButtonClick = async () => {
    const isValid = await validateForm();
    if (isValid) {
      setBtnDisabled(true);
      try {
        const response = await axios.post(`${API.BASE_URL}outlet/user/signin`, 
          { 
            "username": cashier,
            "password": password 
          }
        ); 
        if ( response?.data?.status === true ) {
          console.log('Login successful:', response.data);
          navigate("/onlineOrders", { state: { message: true } });
          dispatch(loginSuccess({ user: response?.data?.data?.user }));
          localStorage.setItem("token", response?.data?.data?.jwt);
          localStorage.setItem("role", response?.data?.data?.user?.role);
          localStorage.setItem("riderAPIKey", response?.data?.data?.outletZupZapCredentials?.riderAPIKey);
          localStorage.setItem("riderAPISecret", response?.data?.data?.outletZupZapCredentials?.riderAPISecret);
          localStorage.setItem("outletId", response?.data?.data?.shift?.outlet);
          // localStorage.setItem("outletData", response.data.data.outletZupZapCredentials.outlet);
          const outletData = response?.data?.data?.outlet;
          localStorage.setItem('outletData', JSON.stringify(outletData));
          localStorage.setItem("isLoggedIn", 'true');
          toast.success("Login successful!", {
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.error('Error:', error?.response?.data?.data?.message);
        let errorMessage =  error?.response?.data?.data?.message;
        toast.error( errorMessage , {
          autoClose: 3000,
        });
      } finally {
        setBtnDisabled(false);
      }
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleButtonClick();
    }
  };

  return (
    <>
      <Box sx={{ textAlign: 'center' }}>
        <Grid container justifyContent='center'>
            <Grid item md={4}>
              <Toolbar />
              <Toolbar />
              <img src={Images.xpanse} />
              <Typography sx={{ fontSize: '22px', margin: '21px 0 18px 0' }}>POS Login</Typography>
              
              <Box sx={{ textAlign: 'left' }}>
                <label style={{  }}>User email</label>
                <TextField
                  fullWidth
                  id="cashier"
                  sx={{ borderRadius: '8px', backgroundColor: '#fff' }}
                  placeholder="Enter email"
                  variant="outlined"
                  value={cashier}
                  onChange={(e) => handleInputChange('cashier', e.target.value)}
                  error={!!errors.cashier}
                  onKeyPress={handleEnterKeyPress}
                />
                {errors.cashier && (
                  <Typography className="errorText" color="error">
                    {errors.cashier}
                  </Typography>
                )}
              </Box>
              <Box sx={{ textAlign: 'left', marginTop: '21px' }}>
                <label style={{  }}>Password</label>
                <TextField
                  fullWidth
                  id="password"
                  sx={{ borderRadius: '8px', backgroundColor: '#fff' }}
                  placeholder="Enter password"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => handleInputChange('password', e.target.value)}
                  error={!!errors.password}
                  onKeyPress={handleEnterKeyPress}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handlePasswordVisibility} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password && (
                  <Typography className="errorText" color="error">
                    {errors.password}
                  </Typography>
                )}
              </Box>
              <Grid container justifyContent={'center'} style={{ marginTop: '21px'}}>
                <Grid item sm={12} md={12}>
                  <PrimaryButton title={'Proceed'} onClick={handleButtonClick} disabled={btnDisabled} spinner={true} disabledTitle={'Logging In'} style={{ borderRadius: '30px', fontSize: '18px' }} />
                </Grid>
              </Grid>
              <Link to="/forgotPassword" style={{ textDecoration: 'none', color: '#000' }}>
                  <Typography className='cursor-p' sx={{ textDecoration: 'none', fontSize: '12px', marginTop: '15px', fontWeight: '600' }}>Forgot Password?</Typography>
              </Link>
            </Grid>    
        </Grid>
      </Box>
    </>
  );
}

export default Login;