import { Box, Grid, Toolbar, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useSelector } from 'react-redux';
import ShiftCalendar from '../../components/ShiftCalendar';
import { API } from '../../utils/Api';
import axios from 'axios';

function Dashboard() {
  const token = localStorage.getItem("token");
  const userData = useSelector((state) => state.login.user); 
  const userName = userData && userData.user ? userData.user.name : '';
  const [calendarData, setCalendarData] = useState([]);
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const [openRecords, setOpenRecords] = useState(false);
  const [month, setMonth] = useState(new Date().getMonth());

  const fetchData = async () => {
    try {
      const monthForAPI = month + 1;
      const url = `${API.BASE_URL}outlet/user/getMonthlyShiftRecords?month=${monthForAPI}&year=${year}`;
      const response = await axios.get( url, {
        headers: {
          Authorization: `Bearer ${token}`
        },
      });
      setCalendarData(response.data.data.cashierRecords);
      console.log(calendarData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
    
  const getCurrentDayFirstInRecordTime = () => {
    const currentDay = new Date().getDate();
    const currentMonth = new Date().getMonth() + 1;

    const currentDayRecord = calendarData.find(record => {
      return (
        record.dayOfMonth === currentDay &&
        record.currentMonth === currentMonth.toString() &&
        record.documents.length > 0 &&
        record.documents[0].recordType === "IN"
      );
    });

    if (currentDayRecord) {
      const firstInRecordTime = currentDayRecord.documents[0].time;
      return firstInRecordTime;
    }
    return null;
  };

  useEffect(() => {
    fetchData();
  }, [month, year]);

  const firstInRecordTime = getCurrentDayFirstInRecordTime();

  return (
    <>
      <Box className="dashBoard">
        <Toolbar />
        <Grid container spacing={2} md={9} sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#fff', borderRadius: '10px', padding: '0px 20px 15px 5px', margin: '50px 0px 0 40px'}}>
          <Grid item md={4}>
            <Box sx={{ backgroundColor: '#fff', borderRadius: '4px', padding: '15px 20px 15px 20px', justifyContent: 'center', textAlign: 'center' }}>
              <AccountCircleOutlinedIcon sx={{ fontSize: '50px'}} />
              <Typography sx={{ color: '#000', fontSize: '20px' }}>
                {userName}
              </Typography>
              <Box sx={{ backgroundColor: '#FCAF48', padding: '10px 0px 10px 0px', borderRadius: '30px' }}>
                <Typography sx={{ fontSize: '14px' }}>Cashier</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '8px', padding: '35px 20px 35px 20px', justifyContent: 'center', textAlign: 'center' }}>
              <Typography sx={{ color: '#000', fontSize: '30px' }}>
                {firstInRecordTime && firstInRecordTime.substring(0, 5)}
              </Typography>
              <Box sx={{ padding: '10px 0px 10px 0px' }}>
                <Typography sx={{ fontSize: '14px' }}>In Time</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box sx={{ backgroundColor: '#F6F6F6', borderRadius: '8px', padding: '35px 20px 35px 20px', justifyContent: 'center', textAlign: 'center' }}>
              <Typography sx={{ color: '#000', fontSize: '30px' }}>
                -- : -- --
              </Typography>
              <Box sx={{ padding: '10px 0px 10px 0px' }}>
                <Typography sx={{ fontSize: '14px' }}>Out Time</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3} md={9} sx={{ padding: '0px 0px 0px 0px', margin: '20px 0px 0 30px'}}>
          <Grid item md={6}>
            <Box sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#FFF', borderRadius: '8px', padding: '15px 20px 35px 15px', justifyContent: 'center', textAlign: 'center' }}>
              <Typography sx={{ color: '#000', fontSize: '80px' }}>
                36
              </Typography>
              <Box sx={{ padding: '10px 0px 10px 0px' }}>
                <Typography sx={{ fontSize: '16px' }}>No. of orders in your shift</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#FFF', borderRadius: '8px', padding: '15px 20px 35px 15px', justifyContent: 'center', textAlign: 'center' }}>
              <Typography sx={{ color: '#000', fontSize: '80px' }}>
                556
              </Typography>
              <Box sx={{ padding: '10px 0px 10px 0px' }}>
                <Typography sx={{ fontSize: '16px' }}>Total day completed orders</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} md={9} sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#fff', borderRadius: '10px', padding: '0px 20px 15px 5px', margin: '50px 0px 30px 40px'}}>
          <ShiftCalendar />
        </Grid>
      </Box>
    </>
  );
}

export default Dashboard;