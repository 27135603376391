import { Box, Toolbar, Typography, TextField, Grid } from '@mui/material';
import React, { useState } from 'react';
import Images from '../../utils/Images';
import PrimaryButton from '../../components/PrimaryButton';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

function ForgotPassword() {
    const navigate = useNavigate();
    const [cashier, setCashier] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [apiError, setApiError] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(false);

    const schema = Yup.object().shape({
        cashier: Yup.string().required('Cashier is required'),
        email: Yup.string().required('Email is required'),
    });

    const handleInputChange = (field, value) => {
        if (field === 'cashier') {
        setCashier(value);
        } else if (field === 'email') {
        setEmail(value);
        }
        setErrors({ ...errors, [field]: '' });
    };

    const validateForm = async () => {
        try {
            await schema.validate({ cashier, email }, { abortEarly: false });
            return true; // Form is valid
        } catch (error) {
        if (error instanceof Yup.ValidationError) {
            const newErrors = {};
            error.inner.forEach((err) => {
            newErrors[err.path] = err.message;
            });
            setErrors(newErrors);
        }
        return false; // Form is invalid
        }
    };

    const handleButtonClick = async () => {
        const isValid = await validateForm();
        if (isValid) {
        setBtnDisabled(true);
        try {
            const response = await axios.get('https://xpanse.dev.bexcart.com/outlet/user/verify-account',{
                params: {
                    email: email,
                    idnumber: cashier
                }
            }); 
            if ( response.data.status === true ) {
                toast.success("Reset Link Sent to your registered Email", {
                    autoClose: 3000,
                });
            }
        } catch (error) {
            console.error('Error:', error);
            let errorMessage =  error.response.data.data.message;
            toast.error( errorMessage , {
                autoClose: 3000,
            });
        } finally {
            setBtnDisabled(false);
        }
        }
    };

    return (
    <>
        <Box sx={{ position: 'absolute', top: '40px', left: '10px' }}>
            <Toolbar />
            <Link to="/" style={{ textDecoration: 'none', color: '#000' }}>
                <Box className='cursor-p' sx={{ display: 'flex', alignItems: 'center'}}>
                    <KeyboardBackspaceIcon sx={{ fontSize: '35px' }} />
                    <Typography sx={{ fontSize: '16px', marginLeft: '10px' }}>Back to the login</Typography>
                </Box>
            </Link>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
            <Grid container justifyContent='center'>
                <Grid item md={4}>
                    <Toolbar />
                    <Toolbar />
                    <img src={Images.xpanse} />
                    <Typography variant='h5' sx={{ margin: '21px 0 21px 0' }}>Forgot Password</Typography>
                    
                    <Box sx={{ textAlign: 'left' }}>
                        <label style={{  }}>Email</label>
                        <TextField 
                            fullWidth 
                            id="email" 
                            sx={{ borderRadius: '8px', backgroundColor: '#fff' }} 
                            placeholder='Enter' 
                            variant="outlined" 
                            value={email}
                            onChange={(e) => handleInputChange('email', e.target.value)}
                            error={!!errors.email}                                                    
                        />
                        {errors.email && (
                            <Typography className="errorText" color="error">
                                {errors.email}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ textAlign: 'left', marginTop: '21px' }}>
                        <label style={{  }}>Cashier ID</label>
                        <TextField 
                            fullWidth 
                            id="cashier" 
                            sx={{ borderRadius: '8px', backgroundColor: '#fff' }} 
                            placeholder='Enter' 
                            variant="outlined" 
                            value={cashier}
                            onChange={(e) => handleInputChange('cashier', e.target.value)}
                            error={!!errors.cashier}
                        />
                        {errors.cashier && (
                            <Typography className="errorText" color="error">
                                {errors.cashier}
                            </Typography>
                        )}
                    </Box>
                    <Grid container justifyContent={'center'} style={{ marginTop: '27px'}}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <PrimaryButton title={'Send Reset Link'} disabled={btnDisabled} onClick={handleButtonClick} spinner={true} disabledTitle={'Sending'} style={{ borderRadius: '30px', fontSize: '18px' }} />
                        </Grid>
                    </Grid>
                </Grid>    
            </Grid>
        </Box>
    </>
    );
}

export default ForgotPassword;