import './sass/main.scss'
import AppRoutes from './AppRoutes'; 
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScannerHandler from './components/ScannerComponent';
import ScannerComponent from './components/ScannerComponent';
import { API } from './utils/Api';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

function App() {
  const token = localStorage.getItem("token");
  const [scannedData, setScannedData] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    const handleScan = async (e) => {
      const scannedData = e.detail.value;
      setScannedData(scannedData);
      console.log('Scanned id', scannedData);
      try {
        console.log(token,' token before scanning');
        const response = await axios.put(
          `${API.BASE_URL}order/delivered`,
          {
            "scanOrderId": e.detail.value.replace(/"/g, ''),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          console.log('Order status updated successfully:', response.data);
          toast.success("Order status updated successfully", {
            autoClose: 3000,
          });
        } else {
          console.error('Error updating order status:', response.data);
        }
      } catch (error) {
        console.error('Error updating order status:', error);
      }
    };
    document.addEventListener('scanEnd', handleScan);
    return () => {
      document.removeEventListener('scanEnd', handleScan);
    };
  }, []);
  
  const theme = createTheme({
    typography: {
      fontFamily: 'Red Hat Text, sans-serif',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <BrowserRouter>
          <ScannerComponent />
          <AppRoutes />
        </BrowserRouter>
        <ToastContainer position="bottom-center" autoClose={3000} theme="colored" hideProgressBar={true} pauseOnHover={false} limit={3} />
      </div>
    </ThemeProvider>
  );
}

export default App;

// useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   let accumulatedData = "";
  
  //   const handleScan = async (e) => {
  //     const scannedPart = e.detail.value;
  //     accumulatedData += scannedPart;
  //     setScannedData(accumulatedData);
  //     console.log('Accumulated data:', accumulatedData);
  
  //     const expectedLength = 24;
  //     if (accumulatedData.length >= expectedLength) {
  //       const trimmedData = accumulatedData.slice(0, expectedLength);
  //       console.log('Token before scanning:', token);
  //       let requestBody = { "_id": trimmedData.replace(/"/g, '') };
  
  //       try {
  //         const response = await axios.put(
  //           `${API.BASE_URL}order/delivered`, requestBody,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //             },
  //           }
  //         );
  
  //         if (response.status === 200) {
  //           console.log('Order status updated successfully:', response.data);
  //           toast.success("Order status updated successfully", { autoClose: 3000 });
  //         } else {
  //           console.error('Error updating order status:', response.data.response);
  //         }
  //       } catch (error) {
  //         console.error('Error updating order status after api call:', error?.response?.data?.error || error?.response?.data?.data?.message);
  //       } finally {
  //         // Reset accumulatedData after processing, successful or not
  //         accumulatedData = "";
  //         setScannedData("");  // Assuming you have a state updater like this
  //       }
  //     } else {
  //       console.log('Data is still incomplete:', accumulatedData);
  //     }
  //   };
  
  //   document.addEventListener('scanEnd', handleScan);
  
  //   return () => {
  //     document.removeEventListener('scanEnd', handleScan);
  //   };
  // }, []);
  
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   const handleScan = async (e) => {
  //     const scannedData = e.detail.value;
  //     setScannedData(scannedData);
  //     console.log(scannedData);
  //     try {
  //       console.log(token,' token before scanning');
  //       const response = await axios.put(
  //         `${API.BASE_URL}order/delivered`,
  //         {
  //           "_id": e.detail.value.replace(/"/g, ''),
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       if (response.status === 200) {
  //         console.log('Order status updated successfully:', response.data);
  //         toast.success("Order status updated successfully", {
  //           autoClose: 3000,
  //         });
  //       } else {
  //         console.error('Error updating order status:', response.data);
  //       }
  //     } catch (error) {
  //       console.error('Error updating order status:', error);
  //     }
  //   };
  //   document.addEventListener('scanEnd', handleScan);
  //   return () => {
  //     document.removeEventListener('scanEnd', handleScan);
  //   };
  // }, []);