import SideBar from "./components/SideBar"
import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"
import NavBar from "./components/NavBar"

const AuthLayout = () => {
    return (
        <div style={{ backgroundColor: '#F6F6F6', minHeight: '100vh' }}>
            <NavBar />
            <Box component="main" sx={{ flexGrow: 2 }}>
                {<Outlet />}
            </Box>
        </div>
    )
}

export default AuthLayout;