import React, { useState } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import OrdersList from '../../components/OrdersList';
import Images from '../../utils/Images';
import { ArrowRight } from '@mui/icons-material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function OrderPayment() {
  const [openModal, setOpenModal] = useState(false);
  const [selectedChipIndex, setSelectedChipIndex] = useState(null);
  const [selectedMode, setSelectedMode] = useState(null);
  const [paymentEnabled, setPaymentEnabled] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChipClick = (index) => {
    setSelectedChipIndex(index === selectedChipIndex ? null : index);
  };

  const handleModeSelect = (modeId) => {
    setSelectedMode(modeId);
    setPaymentEnabled(true);
  };

  const handlePayment = () => {
    console.log('Selected mode', selectedMode);
    console.log('Payment processed');
  };

  const paymentModes = [
    { id: 1, name: 'Card', image: `${Images.card}` },
    { id: 2, name: 'Cash', image: `${Images.cash}` },
    { id: 3, name: 'UPI', image: `${Images.upi}` },
  ];

  return (
    <>
      <Box>
        <Grid container className="flex-section">
          <Grid item md={6} sx={{ backgroundColor: '#F6F6F6' }}>
            <Box sx={{ marginTop: '95px', padding: '10px 30px 40px 30px'}}>
              <Box sx={{ paddingBottom: '10px'}}>
                <Typography sx={{ fontSize: '22px' }}>Order <span style={{ fontWeight: 'bold'}}> #240</span></Typography>
                <Typography className='orderDetailsSmall'>Customer Number</Typography>
                <Typography className='orderDetailsSmall'>9113029491</Typography>
                <Typography className='orderDetailsSmall'>ARRIVAL TIME : 17:30</Typography>
              </Box>
              <Box>
                <OrdersList />
                <OrdersList />
                <OrdersList />
                <OrdersList />
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} sx={{ backgroundColor: '#FFF' }}>
            <Box sx={{ marginTop: '95px', padding: '10px 30px 20px 30px'}}>
              <Typography sx={{ textAlign: 'center', fontSize: '22px' }}>Payment</Typography>
              <Grid container sx={{ padding: '0px 0 2px 0px'}}>
                <Grid item md={12}>
                  <Typography sx={{ fontSize: '16px', fontWeight: '600' }}>Order Summary</Typography>
                  <Grid container sx={{ padding: '4px 0 4px 0'}}>
                    <Grid item md={10}>
                      <Typography className="onlineOrderFont" >
                        Item(s)
                      </Typography>
                    </Grid>
                    <Grid item md={2} sx={{ textAlign: 'end'}}>
                      <Typography className='onlineOrderFont'>
                        6                 
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ padding: '4px 0 4px 0'}}>
                    <Grid item md={10}>
                      <Typography className='onlineOrderFont'>
                        Total MRP
                      </Typography>
                    </Grid>
                    <Grid item md={2} sx={{ textAlign: 'end'}}>
                      <Typography className='onlineOrderFont'>
                        ₹ 4200    
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ padding: '4px 0 4px 0'}}>
                    <Grid item md={10}>
                      <Typography className='onlineOrderFont'>
                        Total Sale Price
                      </Typography>
                    </Grid>
                    <Grid item md={2} sx={{ textAlign: 'end'}}>
                      <Typography className='onlineOrderFont'>
                        ₹ 4200    
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ padding: '4px 0 4px 0', borderBottom: '0.2px solid #D8D3CD' }}>
                    <Grid item md={10}>
                      <Typography className='onlineOrderFont'>
                        Discounts
                      </Typography>
                    </Grid>
                    <Grid item md={2} sx={{ textAlign: 'end'}}>
                      <Typography className='onlineOrderFont'>
                        FREE!    
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container sx={{ padding: '4px 0 4px 0'}}>
                    <Grid item md={10}>
                      <Typography sx={{ fontSize: '22px' }}>
                        Total Payable
                      </Typography>
                    </Grid>
                    <Grid item md={2} sx={{ textAlign: 'end'}}>
                      <Typography sx={{ fontSize: '22px' }}>
                        ₹ 1528
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Grid container sx={{ display: 'flex', justifyContent: 'center'}}>
            {paymentModes.map((mode) => (
                <Grid item md={3}>
                  <Button
                    key={mode.id}
                    variant="contained"
                    style={{
                      borderRadius: '4px',
                      backgroundColor: selectedMode === mode.id ? '#003049' : '#B8B8B8',
                      color: selectedMode === mode.id ? '#fff' : '#fff',
                      width: '112px',
                      height: '90px',
                      padding: '5px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      textTransform: 'capitalize'
                    }}
                    onClick={() => handleModeSelect(mode.id)}
                  >
                    <img
                      src={mode.image}
                      alt={mode.name}
                      style={{ maxWidth: '50px', maxHeight: '50px', marginBottom: '5px' }}
                    />
                    {mode.name}
                  </Button>
                </Grid>
              ))}
            </Grid>
            <Box 
            onClick={handlePayment}
            className="cursor-p"
            disabled={!paymentEnabled}
            sx={{ backgroundColor: paymentEnabled ? '#003049' : '#CCCCCC', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '67%', height: '43px', alignItems: 'center', margin: '15px 0px 0 82px', padding: '18px', borderRadius: '30px', color: '#fff' }}>
                <Typography>$ 420</Typography>
                <Stack direction={'row'}>
                  <Typography>Pay</Typography>
                  <ChevronRightIcon />
                </Stack>
              </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default OrderPayment;