import React, { useState, useEffect } from "react";
import { Button, Grid, Typography, TextField } from '@mui/material';
import PrimaryButton from "./PrimaryButton";
import { Link } from 'react-router-dom';

function PhoneNumber() {
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleNumberClick = (number) => {
    if (phoneNumber.length < 10) {
      setPhoneNumber(phoneNumber + number);
    }
  };

  const handleBackspaceClick = () => {
    setPhoneNumber(phoneNumber.slice(0, -1));
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      const keyPressed = event.key;
      const numericKeys = /[0-9]/;

      if (numericKeys.test(keyPressed) && phoneNumber.length < 10) {
        setPhoneNumber(phoneNumber + keyPressed);
      } else if (keyPressed === 'Backspace' && phoneNumber.length > 0) {
        setPhoneNumber(phoneNumber.slice(0, -1));
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [phoneNumber]);

  const isPhoneNumberValid = phoneNumber.length === 10;

  return (
    <>
      <Typography sx={{ fontSize: '12px', paddingBottom: '5px' }}>Customer Number</Typography>
      <TextField
        variant="outlined"
        value={phoneNumber}
        fullWidth
        disabled
        sx={{ marginBottom: '25px' }}
      />
      <Grid container spacing={2} sx={{ padding: '0 0px 0 25px' }}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0].map((number) => (
          <Grid item xs={4} key={number}>
            <Button
              fullWidth
              variant="outlined"
              style={{
                borderRadius: '50px',
                height: '60px',
                width: '50px',
                backgroundColor: '#E5E5E5',
                color: '#000',
                border: 'none',
                fontSize: '30px',
              }}
              onClick={() => handleNumberClick(number)}
            >
              {number}
            </Button>
          </Grid>
        ))}
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="outlined"
            onClick={handleBackspaceClick}
            style={{
              borderRadius: '50px',
              height: '60px',
              width: '50px',
              backgroundColor: '#E5E5E5',
              color: '#000',
              border: 'none',
              fontSize: '30px',
            }}
            disabled={phoneNumber.length === 0}
          >
            {'<'}
          </Button>
        </Grid>
      </Grid>
      <Link to={isPhoneNumberValid ? '/orderPayment' : '#'} style={{ textDecoration: 'none' }}>
        <PrimaryButton title={'Proceed'} style={{ borderRadius: '30px', fontSize: '18px', margin: '30px 0 0 0', backgroundColor: isPhoneNumberValid ? '#003049' : '#CCCCCC', color: isPhoneNumberValid ? '#FFFFFF' : '#CCCCC', pointerEvents: isPhoneNumberValid ? 'auto' : 'none' }} />
      </Link>
    </>
  );
}

export default PhoneNumber;