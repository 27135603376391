import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, Dialog, DialogTitle, DialogContent, Stack, CircularProgress, Popover, Paper } from '@mui/material';
import PrimaryButton from '../../components/PrimaryButton';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Images from '../../utils/Images';
import FilterChip, { CategoryFilterChip } from '../../components/FilterChip';
import PhoneNumber from '../../components/PhoneNumber';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API } from '../../utils/Api';
import _debounce from 'lodash.debounce';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import OrdersList from '../../components/OrdersList';

function Orders() {
  const [openModal, setOpenModal] = useState(false);
  const token = localStorage.getItem("token");
  const outletId = localStorage.getItem("outletId");
  const [selectedChipIndex, setSelectedChipIndex] = useState(null);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    console.log("Selected Product Details:", product);
    handleOpenModal();
  };

  const handleOpenModal = (product) => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };  
  
  const getCategoriesList = async () => {
    try {
      console.log('token', token);
      const response = await axios.get(`${API.BASE_URL}masters/category/getCategoryWithProducts/${outletId}`,{
        headers: {
          Authorization: `Bearer ${token}`
        },
      }); 
      setCategories(response.data.data.categories);
      console.log('Categories fetched successfully:', response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };  
  
  const handleChipClick = async (categoryId, index) => {
    if (index === selectedChipIndex) {
      setSelectedChipIndex(null);
      await getProductsList(searchQuery, null);
    } else {
      setSelectedChipIndex(index);
      await getProductsList(searchQuery, categoryId);
    }
  };

  const getProductsList = async (searchText, categoryId) => {
    try {
      setLoading(true);
      console.log('token', token);
      const response = await axios.get(`${API.BASE_URL}product/List`,{
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          search: searchText,
          category: categoryId
        }
      });
      setProducts(response.data.data.products);
      console.log('Products fetched successfully:', response.data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (searchText) => {
    setSearchQuery(searchText);
    if (selectedChipIndex !== null) {
      const categoryId = categories[selectedChipIndex]._id;
      await getProductsList(searchText, categoryId);
    } else {
      await getProductsList(searchText, null);
    }
  };

  useEffect(() =>{
    getProductsList();
    getCategoriesList();
  }, []);
  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const [selectedAddons, setSelectedAddons] = useState({});

  const handleAddonChipClick = (addonId, valueIndex) => {
    setSelectedAddons(prevState => ({
      ...prevState,
      [addonId]: valueIndex
    }));
  };

  return (
    <>
      <Box>
        <Grid container className="flex-section">
          <Grid sx={{ backgroundColor: '#F6F6F6', paddingTop: '120px'}} className='flex-no-scroll' item md={8}>
            <Box sx={{ minHeight: '100vh', overflow: 'auto', padding: '0 35px 0 35px', marginRight: '10px' }} className='custom-scrollbar'>
               <TextField
                  fullWidth
                  sx={{ borderRadius: '8px', backgroundColor: '#fff' }}
                  placeholder='Search'
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    handleSearch(e.target.value);
                  }}
                />
              <Grid container justifyContent={'center'}>
                <Grid item md={12} sx={{ margin: '15px 0 15px 0'}}>
                  <Stack direction={'row'} spacing={1}>
                    {categories.map((category, index) => (
                      <CategoryFilterChip
                        key={category._id}
                        filterText={category.name}
                        isSelected={selectedChipIndex === index}
                        onClick={() => handleChipClick(category._id, index)}
                      />
                    ))}
                  </Stack>
                </Grid>
                {loading ? (
                  <Grid item md={3} sx={{ margin: '20px', textAlign: 'center' }}>
                      <CircularProgress sx={{ color: '#000', margin: '10px 0 15px 0' }} /><br/>
                      <Typography variant='paragraph'>Fetching Products...</Typography>
                  </Grid>
                ) : (
                  <>
                    {products.length > 0 ? (
                      products.map((product) => (
                        <>
                        <Grid item md={3} key={product._id} className='cursor-p' onClick={product.isExhauted ? handlePopoverOpen : () => handleProductClick(product) } sx={{ backgroundColor: product.isExhauted ? '#FECBCB' : 'white', margin: '20px', textAlign: 'center', padding: '18px 0 18px 0', borderRadius: '12px' }}>
                          { product.isExhauted && (
                              <ReportProblemIcon sx={{ position: 'absolute', color: 'red', paddingLeft: '5px' }} />
                            )
                          }
                          <img src={product.displayImage} alt={product.name} style={{ maxWidth: '100%', maxHeight: '200px' }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = Images.product;
                            }}
                          />
                          <Typography>{product.name}</Typography>
                        </Grid>
                        <Popover
                          open={Boolean(anchorEl)}
                          anchorEl={anchorEl}
                          onClose={handlePopoverClose}
                          elevation={0}
                          sx={{ backgroundColor: 'transparent' }}
                          anchorOrigin={{ vertical: 'center', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                        >
                          <Typography sx={{ fontSize: '14px', padding: '0 10px 0 10px', border: '2px solid red', borderRadius: '2px', backgroundColor: 'transparent'  }}>Product is exhausted</Typography>
                        </Popover>
                        </>
                      ))
                    ) : (
                      <Typography sx={{ textAlign: 'center', fontSize: '16px', margin: '20px' }}>
                        No products available.
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box sx={{ marginTop: '110px', padding: '0 30px 0 30px'}}>
              <Typography sx={{ fontSize: '22px', paddingBottom: '10px' }}>Order <span style={{ fontWeight: 'bold'}}> #_ _ _</span></Typography>
              <OrdersList />
              <PhoneNumber />
            </Box>
          </Grid>
        </Grid>
        <Dialog open={openModal} onClose={handleCloseModal}>
          <Box sx={{ width: '595px'}}>
            <DialogTitle>{selectedProduct?.name}</DialogTitle>
            <DialogContent>
                {selectedProduct?.hasAddon && (
                  <Grid container sx={{ border: '1px solid #000', borderTop: 'none', borderLeft: 'none', borderRight: 'none', padding: '0px 20px 0px 20px', alignItems: 'center' }}>
                    {selectedProduct?.addons.map((addon) => (
                      <React.Fragment key={addon?._id}>
                        <Grid item md={2} sx={{ alignItems: 'center', borderRight: '1px solid #000' }}>
                          <Typography sx={{ fontSize: '14px', fontWeight: '600', alignSelf: 'center' }}>{addon?.addonTitle}</Typography>
                        </Grid>
                        <Grid item md={10} gap={4} display={'flex'} sx={{ padding: '10px 0px 10px 15px' }}>
                          {addon?.addonValues.map((addonValue, index) => (
                            <FilterChip onClick={() => handleAddonChipClick(addon._id, index)} key={addonValue?._id} filterText={addonValue?.value} isSelected={selectedAddons[addon?._id] === index} />
                          ))}
                        </Grid>
                      </React.Fragment>
                    ))}
                  </Grid>
                )}
                <Grid container sx={{ border: '1px solid #000', borderLeft: 'none', borderRight: 'none', padding: '0px 20px 0px 20px', alignItems: 'center'}}>
                  <Grid item md={2} sx={{ alignItems: 'center', borderRight: '1px solid #000'}}>
                    <Typography sx={{ fontSize: '14px', fontWeight: '600', alignSelf: 'center' }}>Size</Typography>
                  </Grid>
                   <Grid item md={10} gap={4} display={'flex'} sx={{ padding: '10px 0px 10px 15px'}}>
                    <FilterChip filterText={'Small'} isSelected={selectedChipIndex === 0} onClick={() => handleChipClick(0)} />
                    <FilterChip filterText={'M'} isSelected={selectedChipIndex === 1} onClick={() => handleChipClick(1)} />
                    <FilterChip filterText={'L'} isSelected={selectedChipIndex === 2} onClick={() => handleChipClick(2)} />
                    <FilterChip filterText={'XL'} isSelected={selectedChipIndex === 3} onClick={() => handleChipClick(3)} />
                  </Grid>
                </Grid>
                <Grid container justifyContent={'space-around'} sx={{ paddingTop: '10px' }}>
                  <Grid item md={4}>
                    <Typography sx={{ fontSize: '22px' }}>Item Total</Typography>
                    <Typography sx={{ fontSize: '22px', fontWeight: '600' }}>₹ {selectedProduct?.offerPrice}</Typography>
                  </Grid>
                  <Grid item md={4} style={{ padding: '10px 0 10px 0'}}>
                    <PrimaryButton title={'Proceed'} style={{ borderRadius: '30px' }}/>
                  </Grid>
                </Grid>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>
    </>
  );
}

export default Orders;