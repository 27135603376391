import { Box, Grid, Stack, Toolbar, Typography, TextField, FormControl, InputLabel, Select, MenuItem, CircularProgress, Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Images from '../../utils/Images';
import axios from 'axios';
import { API } from '../../utils/Api';
import { useSelector } from 'react-redux';
import SendIcon from '@mui/icons-material/Send';
import { toast } from 'react-toastify';
import { CloudDownloadOutlined } from '@mui/icons-material';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

function Inventory() {
  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("role");
  const [rawMaterials, setRawMaterials] = useState([]);
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.login.user); 
  const [searchQuery, setSearchQuery] = useState('');
  const [outletData, setOutletData] = useState(JSON.parse(localStorage.getItem('outletData')));
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [transferQuantities, setTransferQuantities] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  
  const getRawMaterialsList = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API.BASE_URL}stock-transactions/getOutletCurrentStock?outletId=${outletData._id}&limit=100`,{
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          mainCategory: selectedCategory,
          searchRawMaterial: searchQuery
        }
      }
      );
      const totalRecords = response?.data?.data?.totalRecords;
      console.log('totalRecords', response?.data?.data?.rawMaterialCategories);
      setCategories(response?.data?.data?.rawMaterialCategories);
      if (typeof totalRecords === 'string' && totalRecords.includes('No Records')) {
        setRawMaterials([]);
      } else {
        setRawMaterials(totalRecords);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  
  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
  
    const title = capitalizeWords('raw material inventory');
    const textWidth = doc.getTextWidth(title);
    const titleX = (pageWidth - textWidth) / 2;
    doc.text(title, titleX, 20);
  
    doc.setFontSize(10);
  
    const outletName = capitalizeWords(outletData?.name);
    const outletAddress = capitalizeWords(outletData?.address || 'No Address Available');
    const outletContact = `${outletData.email || 'No Email'}, ${outletData.phone || 'No Phone Info'}`;
  
    doc.text(`Outlet: ${outletName}`, 14, 30);
    doc.text(`Address: ${outletAddress}`, 14, 40);
    doc.text(`Contact: ${outletContact}`, 14, 50);
  
    doc.setFontSize(12);
  
    const startY = 60;
  
    const columns = [
      { header: capitalizeWords('#'), dataKey: 'itemNo' },
      { header: capitalizeWords('product name'), dataKey: 'productName' },
      { header: capitalizeWords('category'), dataKey: 'category' },
      { header: capitalizeWords('sub category'), dataKey: 'subcategory' },
      { header: capitalizeWords('stock quantity'), dataKey: 'stockQuantity' },
      { header: capitalizeWords('unit'), dataKey: 'unit' },
    ];
  
    const sortedMaterials = rawMaterials.sort((a, b) => {
      const categoryA = a.rawMaterial.mainCategory.name.toLowerCase();
      const categoryB = b.rawMaterial.mainCategory.name.toLowerCase();
      const subcategoryA = a.rawMaterial.subCategory.name.toLowerCase();
      const subcategoryB = b.rawMaterial.subCategory.name.toLowerCase();
  
      if (categoryA < categoryB) return -1;
      if (categoryA > categoryB) return 1;
      if (subcategoryA < subcategoryB) return -1;
      if (subcategoryA > subcategoryB) return 1;
      return 0;
    });
  
    const rows = sortedMaterials.map((material, index) => ({
      itemNo: index + 1,
      productName: capitalizeWords(material.rawMaterial.name),
      category: capitalizeWords(material.rawMaterial.mainCategory.name),
      subcategory: capitalizeWords(material.rawMaterial.subCategory.name),
      stockQuantity: parseFloat(material.currentStock.toFixed(2)),
      unit: capitalizeWords(material.rawMaterial.unit),
    }));
  
    doc.autoTable({
      columns,
      body: rows,
      startY: startY,
      theme: 'grid',
      styles: { halign: 'center' }, // Center align all columns
      columnStyles: {
        0: { cellWidth: 10 }, // Item number column
        1: { cellWidth: 60 }, // Product name column
        2: { cellWidth: 30 }, // Category column
        3: { cellWidth: 30 }, // Subcategory column
        4: { cellWidth: 30 }, // Stock quantity column
        5: { cellWidth: 20 }, // Unit column
      },
    });
  
    doc.save(`${getCurrentDate()}-Raw-Material-Inventory.pdf`);
  };
  
  const handleQuantityChange = (rawMaterialId, value) => {
    setTransferQuantities((prev) => ({
      ...prev,
      [rawMaterialId]: value
    }));
  };

  const handleDebitStock = async (rawMaterialId, currentStockQuantity) => {
    const transferQuantity = transferQuantities[rawMaterialId] || 0;
    if (transferQuantity <= 0 || transferQuantity > currentStockQuantity) {
      toast.error("Invalid transfer quantity", {
        autoClose: 3000,
      });
      return;
    }
    try {
      const response = await axios.post(`${API.BASE_URL}stock-transactions/debitStock`, 
        { 
          "outletId": outletData._id,
          "outletStockTransferData":[
              {
                "rawMaterialId": rawMaterialId,
                "currentStockQuantity": currentStockQuantity,
                "transferQuantity": transferQuantity
              }
          ]
        }, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      ); 
      if ( response?.data?.status === true ) {
        toast.success("Stock updated successful!", {
          autoClose: 3000,
        });
        getRawMaterialsList();
        setTransferQuantities({});
      }
    } catch (error) {
      console.error('Error:', error?.response?.data?.data?.message);
      let errorMessage =  error?.response?.data?.data?.message;
      toast.error( errorMessage , {
        autoClose: 3000,
      });
    }
  };

  useEffect(() =>{
    getRawMaterialsList();
    getCurrentDate();
  }, [searchQuery, selectedCategory]);
  
  const handleCategoryChange = (event) => {
    const categoryId = event.target.value;
    setSelectedCategory(categoryId);
    console.log('Selected Category ID:', categoryId);
    setCurrentPage(1);
  };

  const handleSearch = (value) => {
    setSearchQuery(String(value).trim());
  };

  const uniqueCategories = categories?.filter(
    (category, index, self) =>
      index === self.findIndex((c) => c?.mainCategory?._id === category?.mainCategory?._id)
  );

  const pageOffset = (currentPage - 1) * itemsPerPage;

  const paginatedMaterials = rawMaterials.slice(pageOffset, currentPage * itemsPerPage);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  // const paginatedMaterials = rawMaterials.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <>
      <Box className="dashBoard">
        <Toolbar />
        <Grid container justifyContent={'center'}>
          <Grid item md={11} flexDirection={'column'}>
            <Grid container md={12} justifyContent={'center'} sx={{ marginTop: '35px' }}>
              <Grid item md={12}>
                <Typography sx={{ fontSize: '16px', margin: '16px 0 0 40px', textAlign: 'center' }}>Raw Inventory</Typography>
              </Grid>
              <Grid item md={5}>
                <TextField
                  fullWidth
                  sx={{
                    backgroundColor: '#fff',
                    marginTop: '5px',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'darkblue',
                        borderWidth: '2px'
                      },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                      height: '56px',
                      '&.Mui-focused fieldset': {
                        borderColor: 'darkblue',
                      },
                    },
                    '& input': {
                      height: '10px',
                    },
                  }}
                  placeholder='Search'
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchQuery}
                  // onChange={(e) => { handleSearch(e.target.value); }}
                  onChange={(e) => {
                    const query = String(e.target.value);
                    setSearchQuery(query);
                    getRawMaterialsList(query);
                  }}
                />
              </Grid>
              <Grid item md={2}>
                <FormControl fullWidth variant="outlined"  sx={{
                    backgroundColor: '#fff',
                    marginTop: '5px',
                    marginLeft: '5px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'darkblue',
                      borderWidth: '2px',
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                      '&.Mui-focused fieldset': {
                        borderColor: 'darkblue',
                      },
                    },
                    '& input': {
                      height: '10px',
                    },
                  }}>
                  <InputLabel id="category-select-label">Category</InputLabel>
                  <Select
                    labelId="category-select-label"
                    id="category-select"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    label="Category"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {uniqueCategories?.map((category) => (
                      <MenuItem key={category?.mainCategory?._id} value={category?.mainCategory?._id}>
                        {category?.mainCategory?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item md={2}>
                <FormControl fullWidth variant="outlined"  sx={{
                    backgroundColor: '#fff',
                    marginTop: '5px',
                    marginLeft: '10px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: 'darkblue',
                      borderWidth: '2px',
                    },
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '10px',
                      '&.Mui-focused fieldset': {
                        borderColor: 'darkblue',
                      },
                    },
                    '& input': {
                      height: '10px',
                    },
                  }}>
                  <InputLabel id="category-select-label">Sub Category</InputLabel>
                  <Select
                    labelId="category-select-label"
                    id="category-select"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    label="Category"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    {uniqueCategories?.map((category) => (
                      <MenuItem key={category?.mainCategory?._id} value={category?.mainCategory?._id}>
                        {category?.mainCategory?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
              <Grid item md={1} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '10px' }}>
                <CloudDownloadOutlined onClick={generatePDF} sx={{ fontSize: '45px', cursor: 'pointer', color: '#003049' }} />
              </Grid>
            </Grid>
            {loading ? (
              <Box sx={{ height: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <CircularProgress /> 
                <Typography sx={{ paddingLeft: '10px' }}>Fetching Raw Materials</Typography>
              </Box>
            ) : (
              <>
              <Grid container md={userRole !== 'CASHIER' ? 11 : 11 } sx={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)', borderRadius: '8px', padding: '0px 0px 0px 0px', margin: '21px 0px 25px 40px'}}>
                { rawMaterials?.length === 0 ? (
                  <Typography sx={{ textAlign: 'center', padding: '20px', fontSize: '16px' }}>
                    No Raw Materials found.
                  </Typography>
                ) : ( 
                <>
                  <Grid item md={userRole !== 'CASHIER' ? 1 : 2} sx={{ padding: '20px 10px 20px 10px', borderRight: '1px solid grey'}}>
                    <Typography sx={{ textAlign: 'center', fontSize: '15px'}}>Item No</Typography>
                  </Grid>
                  <Grid item md={userRole !== 'CASHIER' ? 5 : 7} sx={{ padding: '20px 0px 20px 0px', borderRight: '1px solid grey'}}>
                    <Typography sx={{ textAlign: 'center', fontSize: '15px'}}>Product Details</Typography>
                  </Grid>
                  <Grid item md={userRole !== 'CASHIER' ? 2 : 3} sx={{ padding: '20px 0px 20px 0px', borderRight: userRole !== 'CASHIER' ? '1px solid grey' : 'none'}}>
                    <Typography sx={{ textAlign: 'center', fontSize: '15px'}}>In Stock</Typography>
                  </Grid>
                  {userRole !== 'CASHIER' && ( 
                    <Grid item md={4} sx={{ padding: '20px 0px 20px 0px' }}>
                      <Typography sx={{ textAlign: 'center', fontSize: '15px' }}>Total Available Quantity</Typography>
                    </Grid>
                  )}
                  <>
                    {paginatedMaterials?.length === 0 ? (
                      <Typography sx={{ textAlign: 'center', padding: '20px', fontSize: '16px' }}>
                        No matching products found.
                      </Typography>
                    ) : (
                      paginatedMaterials?.map((material, index) => {
                        const displayIndex = pageOffset + index + 1;
                        const isEvenRow = index % 2 === 0;
                        const backgroundColor = material.stockInfo === "Out of Stock" ? '#FFCDD2' : (isEvenRow ? '#EDF9FF' : '#fff');
                        return (
                          <React.Fragment key={index}>
                            <Grid item md={userRole !== 'CASHIER' ? 1 : 2} sx={{ position: 'relative', backgroundColor, padding: '20px 10px 20px 10px', borderRight: '1px solid grey', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Typography sx={{  fontSize: '15px'}}>{displayIndex}</Typography>
                            </Grid>
                            <Grid item md={userRole !== 'CASHIER' ? 5 : 7} sx={{ position: 'relative', backgroundColor, padding: '20px 0px', borderRight: '1px solid grey' }}>
                              <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
                                <Box sx={{ width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <img
                                    src={material.rawMaterial.displayImage || Images.beans}
                                    style={{ height: '80px', width: '80px', objectFit: 'contain' }}
                                    alt={material.rawMaterial.name}
                                  />
                                </Box>
                                <Box sx={{ width: '70%' }}>
                                  <Typography sx={{ fontSize: '16px', fontWeight: 'bold', textTransform: 'capitalize' }}>
                                    {material.rawMaterial.name}
                                  </Typography>
                                  <Typography sx={{ fontSize: '14px', textTransform: 'capitalize' }}>
                                    {material.rawMaterial.mainCategory.name} &gt; {material.rawMaterial.subCategory.name}
                                  </Typography>
                                </Box>
                                {material.stockInfo === "Out of Stock" && (
                                  <Box sx={{ position: 'absolute', top: 10, right: 10, backgroundColor: 'red', color: 'white', fontSize: '12px', padding: '2px 6px', borderRadius: '3px', zIndex: 1, }} >
                                    Out of Stock
                                  </Box>
                                )}
                              </Stack>
                            </Grid>
                            <Grid item md={userRole !== 'CASHIER' ? 2 : 3} sx={{ backgroundColor, padding: '20px 10px 20px 10px', borderRight: userRole !== 'CASHIER' ? '1px solid grey' : 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Typography sx={{  fontSize: '15px',  }}>{material.currentStock.toFixed(2)} {material.rawMaterial.unit}</Typography>
                            </Grid>
                            {userRole !== 'CASHIER' && (
                              <Grid item md={4} sx={{ backgroundColor, padding: '20px 10px 20px 10px', display: 'flex', justifyContent: 'center' }}>
                                <Stack direction={'row'} sx={{ backgroundColor: 'none', display: 'flex', alignItems: 'center' }}>
                                  <TextField
                                    fullWidth
                                    value={transferQuantities[material.rawMaterial._id] || ''}
                                    onChange={(e) => handleQuantityChange(material.rawMaterial._id, e.target.value)}
                                    sx={{
                                    borderRadius: '10px',
                                    backgroundColor: '#fff',
                                    marginTop: '5px',
                                      '& .MuiOutlinedInput-root': {
                                        borderRadius: '10px',
                                      },
                                      '& input': {
                                        height: '10px',
                                      },
                                    }}
                                    variant="outlined"
                                  />
                                  <Box onClick={() => handleDebitStock(material.rawMaterial._id, material.currentStock)} className='cursor-p' sx={{ backgroundColor: '#003049', color: '#fff', padding: '5px', margin: '3px 0 0 10px', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                                    <SendIcon />
                                  </Box>
                                </Stack>
                              </Grid>
                            )}
                          </React.Fragment>
                        );
                      })
                    )}
                  </>
                </>
                )}
              </Grid>
              <Grid container justifyContent="center" sx={{ margin: '20px 0' }}>
                <Pagination
                  count={Math.ceil(rawMaterials.length / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  shape="rounded"
                  variant="outlined"
                />
              </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Inventory;