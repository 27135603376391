import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, TextField, Stack, CircularProgress, Paper, Popover } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import SearchIcon from '@mui/icons-material/Search';
import Images from '../../utils/Images';
import { CategoryFilterChip } from '../../components/FilterChip';
import axios from 'axios';
import { API } from '../../utils/Api';

function Products() {
  const token = localStorage.getItem("token");
  const outletId = localStorage.getItem("outletId");
  const [selectedChipIndex, setSelectedChipIndex] = useState(null);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    console.log(selectedProduct);
  };

  const getCategoriesList = async () => {
    try {
      console.log('token', token);
      const response = await axios.get(`${API.BASE_URL}masters/category/getCategoryWithProducts/${outletId}`,{
        headers: {
          Authorization: `Bearer ${token}`
        },
      }); 
      setCategories(response.data.data.categories);
      console.log('Categories fetched successfully:', response.data);
    } catch (error) {
      console.error('Categories fetching Error:', error);
    }
  };  
  
  const handleChipClick = async (categoryId, index) => {
    if (index === selectedChipIndex) {
      setSelectedChipIndex(null);
      await getProductsList(searchQuery, null);
    } else {
      setSelectedChipIndex(index);
      await getProductsList(searchQuery, categoryId);
    }
  };

  const getProductsList = async (searchText, categoryId) => {
    try {
      console.log('token', token);
      setLoading(true);
      const response = await axios.get(`${API.BASE_URL}product/List`,{
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          search: searchText,
          category: categoryId
        }
      });
      setProducts(response.data.data.products);
      console.log('Products fetched successfully:', response.data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async (searchText) => {
    setSearchQuery(searchText);
    if (selectedChipIndex !== null) {
      const categoryId = categories[selectedChipIndex]._id;
      await getProductsList(searchText, categoryId);
    } else {
      await getProductsList(searchText, null);
    }
  };

  useEffect(() =>{
    getProductsList();
    getCategoriesList();
  }, []);
  
  return (
    <>
      <Box>
        <Grid container className="flex-section">
          <Grid sx={{ backgroundColor: '#F6F6F6', paddingTop: '120px'}} className='flex-no-scroll' item md={8}>
            <Box sx={{ minHeight: '100vh', overflow: 'auto', padding: '0 35px 0 35px', marginRight: '10px' }} className='custom-scrollbar'>
               <TextField
                  fullWidth
                  sx={{ borderRadius: '8px', backgroundColor: '#fff' }}
                  placeholder='Search'
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    handleSearch(e.target.value);
                  }}
                />
              <Grid container justifyContent={'center'}>
                <Grid item md={12} sx={{ margin: '15px 0 15px 0' }}>
                  <Stack direction={'row'} spacing={1}>
                    {categories.map((category, index) => (
                      <>
                      <CategoryFilterChip
                        key={category._id}
                        filterText={category.name}
                        isSelected={selectedChipIndex === index}
                        onClick={() => handleChipClick(category._id, index)}
                      />
                      </>
                    ))}
                  </Stack>
                </Grid>
                {loading ? (
                  <Grid item md={3} sx={{ margin: '20px', textAlign: 'center' }}>
                      <CircularProgress sx={{ color: '#000', margin: '10px 0 15px 0' }} /><br/>
                      <Typography variant='paragraph'>Fetching Products...</Typography>
                  </Grid>
                ) : (
                  <>
                  {products.length > 0 ? (
                  products.map((product) => (
                    <Grid item md={3} key={product._id} onClick={() => handleProductClick(product)} className={`cursor-p ${selectedProduct === product ? 'selected-product' : ''}`} sx={{ backgroundColor: product.isExhauted ? '#FECBCB' : '#fff', margin: '20px', textAlign: 'center', padding: '18px 0 18px 0', borderRadius: '12px' }}>
                      { product.isExhausted && (
                          <ReportProblemIcon sx={{ position: 'absolute', color: 'red', paddingLeft: '5px' }} />
                        )
                      }
                      <img src={product.displayImage} alt={product.name} style={{ maxWidth: '100%', maxHeight: '200px' }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = Images.product;
                        }}
                      />
                      <Typography>{product.name}</Typography>
                    </Grid>
                  ))
                  ) : (
                    <Typography sx={{ textAlign: 'center', fontSize: '16px', margin: '20px' }}>
                      No products available.
                    </Typography>
                  )}
                  </>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid item md={4}>
            <Box sx={{ marginTop: '110px', padding: '0 30px 0 30px'}}>
              {selectedProduct ? (
                <Grid item md={12}>
                  <Typography sx={{ fontSize: '22px', paddingBottom: '10px', fontWeight: '500' }}>{selectedProduct.name}</Typography>
                  <Grid item md={11}>
                    { selectedProduct.isExhausted && (
                        <Typography sx={{ fontSize: '14px', padding: '15px 10px 15px 10px', border: '2px solid red', borderRadius: '6px', backgroundColor: '#FECBCB'  }}>Product is exhausted</Typography>
                      )
                    }
                    <Typography sx={{ fontSize: '16px', margin: '15px 0 5px 0' }}>Category</Typography>
                    <Typography sx={{ fontSize: '16px', borderRadius: '6px', backgroundColor: '#F6F6F6', padding: '5px 0px 5px 10px' }}>{selectedProduct.category.name}</Typography>
                    <Typography sx={{ fontSize: '16px', margin: '10px 0 5px 0' }}>Description</Typography>
                    <Typography sx={{ fontSize: '16px', borderRadius: '6px', backgroundColor: '#F6F6F6', padding: '5px 0px 5px 10px' }}>{selectedProduct.description}</Typography>
                    <Typography sx={{ fontSize: '16px', margin: '10px 0 5px 0' }}>MRP Price</Typography>
                    <Typography sx={{ fontSize: '16px', borderRadius: '6px', backgroundColor: '#F6F6F6', padding: '5px 0px 5px 10px' }}>₹ {selectedProduct.mrp}</Typography>
                    <Typography sx={{ fontSize: '16px', margin: '10px 0 5px 0' }}>Offer Price</Typography>
                    <Typography sx={{ fontSize: '16px', borderRadius: '6px', backgroundColor: '#F6F6F6', padding: '5px 0px 5px 10px' }}>₹ {selectedProduct.offerPrice}</Typography>
                    <Typography sx={{ fontSize: '16px', margin: '10px 0 5px 0' }}>Making Time</Typography>
                    <Typography sx={{ fontSize: '16px', borderRadius: '6px', backgroundColor: '#F6F6F6', padding: '5px 0px 5px 10px' }}>{selectedProduct.makingTime} minutes</Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid item md={12} sx={{ paddingTop: '25%', textAlign: 'center', justifyContent: 'center' }}>
                  <Typography sx={{ fontSize: '22px' }}>Select a product for details</Typography>
                  <img src={Images.noProductSelected} alt='product-not-selcted' />
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Products;