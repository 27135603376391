import React from "react";
import { Box, Button, Grid, Toolbar, Chip, Typography, Tab, Tabs, TextField, Dialog, DialogTitle, DialogContent, Stack, Divider } from '@mui/material';
import { Add, CancelOutlined, Remove } from '@mui/icons-material';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import PrimaryButton from "./PrimaryButton";

function OrdersList() {
    return(
      <>
        <Grid container justifyContent="space-between">
          <Grid item md={12} className='shadowed-box' sx={{ borderRadius: '10px', padding: '10px', backgroundColor: '#fff', margin: '10px 0 10px 0' }}>
            <Grid container sx={{ padding: '4px 0 4px 0'}}>
              <Grid item md={11} sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography className="onlineOrderFont" >
                  CapAmericano - ₹1500
                </Typography>
              </Grid>
              <Grid item md={1} sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <SaveAsIcon sx={{ color: '#D9D9D9'}} className='cursor-p' />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item className='attributes'>XXL</Grid>
              <Grid item className='attributes'>Regular Milk</Grid>
              <Grid item className='attributes'>Hot</Grid>
              <Grid item className='attributes'>No Flavour</Grid>
            </Grid>
            <Stack direction='row' justifyContent={'space-between'} sx={{ padding: '20px 0 10px 0', alignItems: 'center' }}>
              <CancelOutlined sx={{ color: '#D9D9D9'}} className='cursor-p' />
              <Box>
                <Stack direction={"row"} spacing={1}>
                    <Remove
                          className='cursor-p' style={{ fontSize: "2rem", color: '#fff', backgroundColor: '#003049', borderRadius: '50px' }}
                    />
                    <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{
                            width: "30px",
                            textAlign: "center",
                        }}
                    >
                        0
                    </Stack>
                    <Add
                        className='cursor-p' style={{ fontSize: "2rem", color: '#fff', backgroundColor: '#003049', borderRadius: '50px' }}
                    />
                </Stack>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </>
    );
};

export default OrdersList;