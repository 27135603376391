import SideBar from "./components/SideBar"
import { Box } from "@mui/material"
import { Outlet } from "react-router-dom"

const AppLayout = () => {
    return (
        <div style={{ display: 'flex' }}>
            <Box>
                <SideBar />
            </Box>
            <Box component="main" sx={{ flexGrow: 2 }}>
                {<Outlet />}
            </Box>
        </div>
    )
}

export default AppLayout;